import axios from "axios";

import { useEffect, useState, useContext, useRef, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChatMessage, { Message } from "./ChatMessage";
import { PromptContext } from "../../context/PromptContext";
import { LoadingContext } from "../../context/LoadingContext";
import { SettingContext } from "../../context/SettingContext";
import { ChatContext } from "../../context/ChatContext";

import { getLeadProfile } from "../../services/profile";

import { useHistoryFunctions } from "../../providers/historyProvider";
import { formatInput, formatMarkdown } from "../../utils/chat";
import { CHAT_DISABLED_ID, ELYSIA_GUARDRAIL_ERROR_MESSAGE, ELYSIA_INTERNAL_ERROR_MESSAGE } from "../../config/settings";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";

// Initialize the variables to handle api abort, cancel streaming
let cancelStreaming = false;
let isStreaming = false;
let controller = new AbortController();
let signal = controller.signal;
let metadataSourceList = [];
let toolCallKey = "toolCall";
const ChatSession = () => {
  const navigate = useNavigate();
  const { prompt, setPrompt, isProcessed, setProcessed } = useContext(PromptContext);
  const { activeChatHistoryRecord, setIsStreamingRequested } = useContext(ChatContext);
  const { setIsLoading } = useContext(LoadingContext);
  const activeChatHistoryConversation = activeChatHistoryRecord?.history || [];
  const historyFunctions = useHistoryFunctions();
  const [isStreamCompleted, setIsStreamCompleted] = useState<boolean>(false);
  const location = useLocation();

  if (!historyFunctions) {
    throw new Error('History functions are not available.');
  }
  const { addChatToToday, updateSessionTitle, updateChatHistoryOnDelete } = historyFunctions;
  const {
    modelProvider,
    modelName,
    personality,
    creativity,
    role,
    domainExpertise,
    writingStyle,
    outputLanguage,
    showSourceList,
  } = useContext(SettingContext);
  const {
    chatSessionId,
    newChat,
    clearState,
    chatAgent,
    context,
    contextName,
    sources,
    nextBestActions,
    entryPoint,
    includeSearch,
    isChatActive,
    isPrivateChat,
    setNewChat,
    setClearState,
    setIsNewSession,
    setSessionPath,
    setBackToChatSession,
    setIsChatActive,
    localStopStreamingKey = "stopStreaming",
    localToolResultKey = "toolResult",
    localAutoScrollEnabledKey = "autoScrollEnabled",
    availablePrivateDocs,
    isChatDisabled,
    setIsChatDisabled
  } = useContext(ChatContext);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [result, setResult] = useState<string | null>(null);
  const [toolCall, setToolCall] = useState("");
  const [toolResult, setToolResults] = useState<any[]>([]);
  const { setDynamicStreamCount } = useContext(ChatContext);

  const scrollRef = useRef<HTMLDivElement>(null);
  const resultScrollRef = useRef<HTMLDivElement>(null);
  const [runId, setRunId] = useState<string>("");

  //if any of the setting context changes - get notified, but do nothing immediately
  useEffect(() => {
    //do nothing immediately
  }, [
    modelProvider,
    modelName,
    personality,
    creativity,
    role,
    domainExpertise,
    writingStyle,
    outputLanguage,
    showSourceList,
    includeSearch
  ]);

  //if agent changes, clear state and start over again
  useEffect(() => {
    setPrompt?.("");
    if (clearState || sessionStorage.getItem(localStopStreamingKey) === "true") {
      cancelStreaming = true;
      // abort the api when streaming isn't started yet
      if (!isStreaming) {
        controller.abort();
        setIsStreamingRequested?.(false);
      }
      if (sessionStorage.getItem(localStopStreamingKey) !== "true") {
        setChatMessages([]);
      }
    }
    // eslint-disable-next-line
  }, [setPrompt, clearState, chatAgent, context, contextName, nextBestActions, activeChatHistoryRecord, sessionStorage.getItem(localStopStreamingKey)]);

  //if prompt from text input changes, react based on the chosen agent (in context)
  useEffect(() => {
    if (prompt && prompt !== "" && !isProcessed) {
      if (prompt !== "" && !prompt.startsWith("auto-trigger-prompt")) {
        let newMessage: Message = {
          type: "User",
          body: prompt
        };
        setChatMessages((prevArray) => [...prevArray, newMessage]);
        setIsChatActive?.(true);
      }
      processPrompt();
      setProcessed?.(true);
    }
    //eslint-disable-next-line
  }, [prompt, isProcessed, setProcessed, setIsLoading]);

  //if chat history is updated in the current chat session
  useEffect(() => {
    if ((chatMessages.length || activeChatHistoryRecord?.session_id) && sessionStorage.getItem(localAutoScrollEnabledKey) === "true") {
      const isMobile = /iPhone|iPad|Android/i.test(navigator.userAgent);
      if (isMobile) {
        setTimeout(() => {
          scrollRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "start",
          });
        }, 50);
      } else {
        // Desktop scrolling
        scrollRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      }
    }
  }, [chatMessages.length, activeChatHistoryRecord?.session_id, localAutoScrollEnabledKey]);

  // abort/cancel the streaming api on component unmount
  useEffect(() => {
    return () => {
      cancelStreaming = true;
      if (!isStreaming) {
        controller.abort();
        setIsStreamingRequested?.(false);
      }
      setIsChatActive?.(false);
      setChatMessages([]);
    }
    // eslint-disable-next-line
  }, [setIsStreamingRequested]);

  // If private chat mode changes, clear chat messages
  useEffect(() => {
    setChatMessages([]);
  }, [isPrivateChat])

  useEffect(() => {
    if (isStreamCompleted) {
      appendMesgToChatHistory(
        result,
        runId,
        localStorage.getItem(localToolResultKey),
        localStorage.getItem(toolCallKey)
      );
    }
    // eslint-disable-next-line
  }, [result, isStreamCompleted, runId])

  useEffect(() => {
    if (!activeChatHistoryConversation?.length || !availablePrivateDocs) return;
    const documentsInChatSession = activeChatHistoryConversation[0]?.references?.map(doc => doc?.title.toString());
    if (!documentsInChatSession?.length) {
      setIsChatDisabled?.(false);
      return;
    }
    const isDocumentDeleted = documentsInChatSession.some(doc => !(availablePrivateDocs?.includes(doc))); // will return true if a document is not found in the private collection
    setIsChatDisabled?.(isDocumentDeleted);

    // eslint-disable-next-line
  }, [activeChatHistoryConversation, availablePrivateDocs, isChatDisabled]);

  useEffect(() => {
    toast.dismiss(CHAT_DISABLED_ID);

    // eslint-disable-next-line
  }, [location])

  let appendMesgToChatHistory = (message, runId, srcList, searchTxt) => {
    let newMessage: Message = {
      type: "System",
      body: message,
      showInteractions: runId && runId !== "" ? true : false,
      prompts: (chatAgent === 'Sales' && entryPoint === 'upload' && nextBestActions ? nextBestActions : []),
      runId: runId,
      references: metadataSourceList,
      toolResult: srcList ? JSON.parse(srcList) : [],
      toolCall: searchTxt
    };
    setResult("");
    setToolCall("");
    setToolResults([]);
    setIsLoading(false);
    setIsStreamCompleted(false);
    metadataSourceList = [];
    localStorage.setItem(toolCallKey, "");
    localStorage.removeItem(localToolResultKey);
    setRunId("");

    // Clear chat messages list on abort
    if (cancelStreaming && sessionStorage.getItem(localStopStreamingKey) !== "true") {
      setChatMessages([]);
      setIsChatActive?.(false);
    } else {
      setChatMessages((prevArray) => [...prevArray, newMessage]);
      setIsChatActive?.(true);
    }
  }

  let processStreamingRequest = async () => {
    let url = getStreamingServiceUrl();
    let payload = getStreamingServicePayload();
    let auth = (axios.defaults.headers).common['Authorization'];
    sessionStorage.setItem(localAutoScrollEnabledKey, "true");

    // Update abort, streaming cancel variables to initial values on api request
    cancelStreaming = false;
    isStreaming = false;
    controller = new AbortController();
    signal = controller.signal;
    metadataSourceList = [];
    setClearState?.(false);

    try {
      sessionStorage.setItem(localStopStreamingKey, "false");
      setIsStreamingRequested?.(true);
      var response = await fetch(url, {
        method: "POST",
        signal: signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${auth}`,
        },
        body: JSON.stringify(payload),
      });
      if (newChat && !isPrivateChat) {
        const chat = {
          chat_history_title: "New Chat",
          is_archived: null,
          session_id: String(chatSessionId),
          updated_on: null,
          is_new_chat: true
        };
        addChatToToday(chat);
        setBackToChatSession?.(false);
      }

      var reader = response?.body?.getReader();
      var decoder = new TextDecoder("utf-8");

      reader?.read().then(function processResult(res) {

        isStreaming = true;
        setDynamicStreamCount?.(prev => prev + 1);

        // Cancel the streaming instead of abort if streaming is already started
        if (cancelStreaming) {
          reader?.cancel();
          setIsStreamingRequested?.(false);
          if (newChat) {
            updateChatHistoryOnDelete(String(chatSessionId));
          }
        }
        if (res.done) {
          setIsStreamCompleted(true);
          setIsStreamingRequested?.(false);
          return;
        }
        let token = decoder.decode(res.value, { stream: true });
        const tokens = token.split('\n');
        let ansTokenCat: string = "";
        let metaToken: any = {};

        tokens.forEach(line => {
          const pLine = JSON.parse(line || "{}");
          if (pLine?.type === "answer") {
            ansTokenCat += pLine?.answer;
          } else if (pLine?.type === "error") {
            ansTokenCat += pLine?.error;
          } else if (pLine?.type === "metadata") {
            metaToken = pLine?.metadata;
          } else if (pLine?.type === "tool_results") {
            const toolRes = [{ toolcall: true, searching: localStorage.getItem(toolCallKey) }, ...pLine?.tool_results];
            // eslint-disable-next-line
            setToolResults(toolRes);
            localStorage.setItem(
              localToolResultKey,
              JSON.stringify(toolRes)
            );
          } else if (pLine?.type === "tool_call") {
            setToolCall(pLine?.args?.query)
            localStorage.setItem(
              toolCallKey,
              pLine?.args?.query
            );
            setTimeout(() => {
              if (sessionStorage.getItem(localAutoScrollEnabledKey) === "true") {
                resultScrollRef.current?.scrollIntoView(true);
              }
            }, 200)
          }
        });
        if (ansTokenCat) {
          setResult((prev) => prev + ansTokenCat);
          if (sessionStorage.getItem(localAutoScrollEnabledKey) === "true") {
            resultScrollRef.current?.scrollIntoView(true);
          }
          if (token === ELYSIA_INTERNAL_ERROR_MESSAGE) {
            if (newChat) {
              updateChatHistoryOnDelete(String(chatSessionId));
            }
          } else if (token === ELYSIA_GUARDRAIL_ERROR_MESSAGE) {
            if (newChat) {
              updateChatHistoryOnDelete(String(chatSessionId));
            }
          }
        }
        if (Object.keys(metaToken).length) {
          try {
            metadataSourceList = metaToken?.references;
            setRunId(metaToken?.run_id);
            if (sessionStorage.getItem(localAutoScrollEnabledKey) === "true") {
              resultScrollRef.current?.scrollIntoView(true);
            }

            let title = metaToken?.title;
            if (
              !cancelStreaming &&
              title &&
              newChat &&
              sessionStorage.getItem(localStopStreamingKey) !== "true" &&
              !isPrivateChat
            ) {
              updateSessionTitle(String(chatSessionId), title);
              setIsNewSession?.(true);
              setSessionPath?.(`/session/${chatSessionId}`);
              if (window.location.pathname === "/") {
                navigate(`/session/${chatSessionId}`, { replace: true });
              }
              setClearState?.(false);
              setNewChat?.(false);
            }

          } catch (e) {
            console.error(e)
          }
        }
        return reader?.read().then(processResult);
      });
    } catch (e: any) {
      setIsStreamingRequested?.(false);
      appendMesgToChatHistory(ELYSIA_INTERNAL_ERROR_MESSAGE, "", "[]", "");
    }
  };

  let processLeadProfileRequest = async () => {
    try {
      let response = await getLeadProfile(prompt);
      appendMesgToChatHistory(response, "", "[]", "");
      return;
    } catch (err) {
      console.log("error fetching profile");
    }
  };

  let processPrompt = async () => {
    if (prompt !== "") {
      setIsLoading(true);
      setResult("");
      if (chatAgent === "Lead") {
        processLeadProfileRequest();
      } else {
        //all other usecases
        if (chatAgent === "Document") {
          setToolCall(chatAgent)
          localStorage.setItem(
            toolCallKey,
            chatAgent
          );
          setTimeout(() => {
            if (sessionStorage.getItem(localAutoScrollEnabledKey) === "true") {
              resultScrollRef.current?.scrollIntoView(true);
            }
          }, 200)
        }
        processStreamingRequest();
      }

    } else {
      alert("Please enter a prompt...");
    }
  };

  const getStreamingServiceUrl = () => {
    return `${process.env.REACT_APP_API_DOMAIN}v2/ai/chat/stream/completion`;
  };

  const getStreamingServicePayload = () => {

    let valPrompt = prompt ? prompt : "";
    let payload = {
      appId: `${process.env.REACT_APP_ELYSIA_APP_ID}`,
      query: valPrompt,
      model: modelProvider,
      name_of_model: modelName,
      tokens: 8192,
      creativity: creativity,
      personality: personality,
      role: role,
      writing_style: writingStyle,
      domain_expertise: domainExpertise,
      input_language: "English",
      output_language: outputLanguage,
      chat_session: chatSessionId,
      private_chat: isPrivateChat,
      system_prompt: "",
      concepts: [],
      entities: [],
      business_units: [],
      products: [],
      content_domains: [],
      showSourceList: showSourceList,
      include_search: includeSearch,
      include_metadata: true,
      intermediate_steps: true
    }

    if (chatAgent === "Document") {
      if (context && context !== "myContentsOnly") {
        payload["context"] = context;
        payload["filterMyContentsOnly"] = false;
        if (sources && sources.length > 0) {
          payload["sources"] = sources;
        }
      }
      if (context && context === "myContentsOnly") {
        payload["filterMyContentsOnly"] = true;
        if (sources && sources.length > 0) {
          payload["sources"] = sources;
        }
      }
      payload.showSourceList = true;
    }
    return payload;
  };


  return (
    <>
      {!isChatActive && !isPrivateChat &&
        <>
          <ChatMessage message={{
            type: "System-Default",
            showInteractions: false
          }} />
        </>
      }

      {
        activeChatHistoryConversation && activeChatHistoryConversation?.length > 0 && (
          activeChatHistoryConversation?.map((record, index) => {
            let toolResult: ChatHistoryConversation["intermediate_steps"] = [];
            if (record?.intermediate_steps?.length > 1) {
              toolResult = [
                { toolcall: true, searching: record?.intermediate_steps[0]?.args?.query },
                // eslint-disable-next-line
                ...(record?.intermediate_steps[1]?.tool_results || [])
              ];
            }
            return (
              <div key={`history_${index}`}>
                <ChatMessage message={{ body: formatInput(record.input), type: "User" }} />
                <ChatMessage
                  message={{
                    body: record.output,
                    type: "System",
                    source_used_for_response: record.source_used_for_response,
                    references: record.references,
                    runId: record?.run_id,
                    feedback: record?.feedback,
                    toolResult
                  }}
                  historySystemMessage
                />
              </div>
            )
          })
        )}
      {isChatActive && chatMessages && chatMessages.length > 0 && (
        <div>
          {chatMessages?.map((chatMsg, index) => {
            return (
              <Fragment key={index + 100}>
                <ChatMessage key={index} message={chatMsg} />
              </Fragment>
            );
          })}

          {isChatActive && (result || toolCall) ? (
            <div aria-hidden="true">
              {
                <ChatMessage
                  message={{
                    type: "System",
                    body: result,
                    toolCall,
                    toolResult
                  }}
                />
              }
              <div ref={resultScrollRef} />
            </div>
          ) : null}
        </div>
      )}
      <div className="sr-only" aria-live="polite" role="status">
        {chatMessages.length > 0 && chatMessages[chatMessages.length - 1].type === 'System' && (
          <>Elysia Says: {(chatMessages[chatMessages.length - 1]?.toolResult?.length ?? 0) > 0 && (
            <>
              Searched: {(chatMessages[chatMessages.length - 1]?.toolResult?.length ?? 0) - 1} sites,
            </>
          )}
            <>
              <ReactMarkdown
                children={formatMarkdown(chatMessages[chatMessages.length - 1]?.body)} />
            </>
          </>
        )}
      </div>
      <div ref={scrollRef} />
    </>
  );
};

export default ChatSession;
