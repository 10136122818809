
import React, { useState, useEffect, FC } from 'react';

import { ChatContext } from '../context/ChatContext';
import { getAllowedCollections, getAllowedRoutes } from "../services/profile";
import icon_loading from "../assets/elysia-loader.gif";
import { generateChatSessionId } from '../utils/chat';
import { getSources } from '../services/content';

interface Props {
    children: JSX.Element,
    user: any
}

export const ChatProvider: FC<Props> = ({ children, user }) => {
    const [clearState, setClearState] = useState<boolean>(false);
    const [newChat, setNewChat] = useState<boolean>(true);
    const [chatSessionId, setChatSessionId] = useState<string>(generateChatSessionId());
    const [chatAgent, setChatAgent] = useState<string>("Generic");
    const [promptInput, setPromptInput] = useState<string>("");
    const [nextBestActions, setNextBestActions] = useState<{ title: string; type: string }[]>([]);
    const [salesCallTranscript, setSalesCallTranscript] = useState<File | undefined>()
    const [context, setContext] = useState("");
    const [contextName, setContextName] = useState("");
    const [sources, setSources] = useState<string[] | undefined>([]);
    const [entryPoint, setEntryPoint] = useState("prompt"); //prompt | upload
    const [refreshSources, setRefreshSources] = useState(0);
    const [includeSearch, setIncludeSearch] = useState<boolean>(true);
    const [allowedCollections, setAllowedCollections] = useState<{ ckey: string; ctype: string; cline: string; cname: string; perm: string }[]>([]);
    const [allowedRoutes, setAllowedRoutes] = useState<string[]>([]);
    const [selectedSources, setSelectedSources] = useState<string[] | undefined>([]);
    const [activeChatHistoryRecord, setActiveChatHistoryRecord] = useState<ChatHistoryRecord>();
    const [collectionName, setCollectionName] = useState("My Private Collection");
    const [collection, setCollection] = useState<string>("myContentsOnly");
    const [collectionType, setCollectionType] = useState<string>("private");
    const [collectionProductLine, setCollectionProductLine] = useState<string>("");
    const [isActiveChatHistoryLoading, setIsActiveChatHistoryLoading] = useState<boolean>(false);
    const [isNewSession, setIsNewSession] = useState<boolean>(false);
    const [sessionPath, setSessionPath] = useState<string>("");
    const [backToChatSession, setBackToChatSession] = useState<boolean>(false);
    const [isRestrictedChatSessionStatus, setIsRestrictedChatSessionStatus] = useState<string>("");
    const [isPrivateChat, setIsPrivateChat] = useState<boolean>(false);
    const [isChatActive, setIsChatActive] = useState<boolean>(false);
    const [isStreamingRequested, setIsStreamingRequested] = useState<boolean>(false);
    const [showScrollIcon, setShowScrollIcon] = useState<boolean>(false);
    const [dynamicStreamCount, setDynamicStreamCount] = useState<number>(0);
    const [uploadedFileTitle, setUploadedFileTitle] = useState<string>("");
    const [showFileToasts, setShowFileToasts] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [wordsCount, setWordsCount] = useState<number>(0);
    const [wordCountError, setWordCountError] = useState<boolean>(false)
    const [localStopStreamingKey, setLocalStopStreamingKey] = useState<string>("");
    const [localAutoScrollEnabledKey, setLocalAutoScrollEnabledKey] = useState<string>("");
    const [localToolResultKey, setLocalToolResultKey] = useState<string>("");
    const [availablePrivateDocs, setAvailablePrivateDocs] = useState<Array<string>>([]);
    const [isChatDisabled, setIsChatDisabled] = useState<boolean>(false);

    useEffect(() => {
        const fetchAllowedCollections = async () => {
            setAllowedCollections(await getAllowedCollections());
        }
        fetchAllowedCollections();
    }, []);

    useEffect(() => {
        const fetchAllowedRoutes = async () => {
            setAllowedRoutes(await getAllowedRoutes());
        }
        fetchAllowedRoutes();
    }, []);

    useEffect(() => {
        const fetchDocs = async () => {
            const response = await getSources("true", "");
            setAvailablePrivateDocs(response?.map(doc => doc?.title));
        }
        fetchDocs();

    }, [])

    const chatContext: ChatContextStore = {
        clearState,
        chatAgent,
        nextBestActions,
        context,
        contextName,
        sources,
        refreshSources,
        salesCallTranscript,
        entryPoint,
        includeSearch,
        allowedCollections,
        allowedRoutes,
        selectedSources,
        collectionName,
        collection,
        collectionType,
        collectionProductLine,
        activeChatHistoryRecord,
        isActiveChatHistoryLoading,
        isNewSession,
        sessionPath,
        backToChatSession,
        newChat,
        chatSessionId,
        isRestrictedChatSessionStatus,
        isPrivateChat,
        isChatActive,
        promptInput,
        isStreamingRequested,
        showScrollIcon,
        dynamicStreamCount,
        uploadedFileTitle,
        showFileToasts,
        uploadedFile,
        wordsCount,
        wordCountError,
        localStopStreamingKey,
        localAutoScrollEnabledKey,
        localToolResultKey,
        availablePrivateDocs,
        isChatDisabled,
        setClearState,
        setChatAgent,
        setNextBestActions,
        setContext,
        setContextName,
        setSources,
        setRefreshSources,
        setSalesCallTranscript,
        setEntryPoint,
        setIncludeSearch,
        setAllowedCollections,
        setAllowedRoutes,
        setSelectedSources,
        setCollection,
        setCollectionType,
        setCollectionProductLine,
        setCollectionName,
        setActiveChatHistoryRecord,
        setIsActiveChatHistoryLoading,
        setIsNewSession,
        setSessionPath,
        setBackToChatSession,
        setNewChat,
        setChatSessionId,
        setIsRestrictedChatSessionStatus,
        setIsPrivateChat,
        setIsChatActive,
        setPromptInput,
        setIsStreamingRequested,
        setShowScrollIcon,
        setDynamicStreamCount,
        setUploadedFileTitle,
        setShowFileToasts,
        setUploadedFile,
        setWordsCount,
        setWordCountError,
        setLocalStopStreamingKey,
        setLocalAutoScrollEnabledKey,
        setLocalToolResultKey,
        setIsChatDisabled,
        setAvailablePrivateDocs
    };


    return (
        <ChatContext.Provider value={chatContext}>
            {user ?
                children
                : <div className="center"><img src={icon_loading} alt="loading" /></div>
            }
        </ChatContext.Provider>
    )
}
