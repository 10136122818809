import Card from "react-bootstrap/Card";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getUserInitials } from "../../utils/user";
import { savePrompt } from "../../services/savedPrompts";
import { Button } from "react-bootstrap";
import { ReactComponent as TickIcon } from "../../assets/icon-google-tick.svg";
import { ReactComponent as BookMarkIcon } from "../../assets/icon-google-bookmark.svg";
import "./style.scss";
import { ChatContext } from "../../context/ChatContext";
import OverlayTooltip from "../common/UI/OverlayTooltip";

const ChatUserMessage = (props) => {
    const user = useContext(AuthContext);
    const { isPrivateChat } = useContext(ChatContext);
    const initials = getUserInitials(user?.username);
    const [showBadge, setShowBadge] = useState(false);

    const handleBadge = () => {
        setShowBadge(true);
        const timeoutRef = setTimeout(() => {
            setShowBadge(false);
            clearTimeout(timeoutRef);
        }, 2000);
    }

    const handleSavePrompt = () => {
        let response;
        (async () => {
            try {
                response = await savePrompt(user, props?.messageBody);;
                if (response) {
                    handleBadge();
                }
            } catch (err) {
                console.log("error saving prompt");
            }
        })();
    }

    return (
        <div className="user-msg-body user-msg-card mb-4">
            <div className="user-icon-display"><div className="user-initials-display" data-testid="user-initials">{initials}</div></div>
            <Card className="ms-4 shadow-sm bg-white rounded custom-card">
                <Card.Body className="custom-card-body position-relative">
                    <Card.Text className="user-msg-card-desc">
                        <div className="user-message-body" data-testid="user-prompt-body"> {props.messageBody}</div>
                        {showBadge ? (
                            <Button
                                onClick={handleSavePrompt}
                                data-testid="saved-prompt-icon"
                                className="saved-prompt-bookmark-icon"
                                aria-label="prompt saved"
                                aria-live="assertive"
                            >
                                <BookMarkIcon />
                            </Button>
                        ) : (
                            <OverlayTooltip
                                tooltipText="Save Prompt"
                                buttonContent={<BookMarkIcon aria-label="save prompt" />}
                                ariaLabel="save prompt"
                                testId="saved-prompt-icon"
                                tooltipClassName="save-prompt-tooltip"
                                buttonClassName={`saved-prompt-bookmark-icon ${isPrivateChat ? 'invisible' : ''}`}
                                onClick={handleSavePrompt}
                                placement="bottom"
                            />
                        )}
                        <div aria-live="assertive">
                            {showBadge && (
                                <span className="history-badge-for-saved-prompt-button position-absolute">
                                    <TickIcon />
                                    <span>Prompt saved</span>
                                </span>
                            )}
                        </div>

                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
};

export default ChatUserMessage;
