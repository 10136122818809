import { ReactComponent as ContentIcon } from "../../assets/icon google-library.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icon-chart.svg";
import { ReactComponent as HelpDocsIcon } from "../../assets/icon-google-help-circle.svg";
import { ReactComponent as HelpIcon } from "../../assets/icon-help.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icon-logout.svg";
import { ReactComponent as SandboxIcon } from "../../assets/icon-sandbox.svg";
import { ReactComponent as BellIcon } from "../../assets/icon_bell.svg";
import icon_chat from "../../assets/icon-google-chat.svg";
import icon_app from "../../assets/icon-google-apps.svg";
import icon_flag from "../../assets/icon-google-flag.svg";
import icon_profile_menu from "../../assets/icon-profile-menu.svg";
import { CommonDropdownProps } from "../common/UI/CommonDropdown";

export const menuConfig = (
  navigate: (path: string) => void,
  logout: () => void,
  initials: string,
  isSandboxRouteAllowed: boolean,
  isDashboardRouteAllowed: boolean
): Record<string, CommonDropdownProps | undefined> => ({
  manageContentMenu: {
    icon: <ContentIcon />,
    tooltipText: "Manage Content",
    ariaLabel: "content menu",
    testId: "manage-content-dropdown",
    className: "icon-header-menu-button me-2",
    dropdownOpenClassName: "dropdown-open-state",
    dropdownItems: [
      {
        key: "1",
        eventKey: "1",
        ariaLabel: "manage content",
        dataTestId: "manage-content-dropdown-item",
        onClick: () => navigate("/mycontent"),
        icon: <ContentIcon />,
        text: "Manage Content",
        role: "menuitem",
        component: "button",
      }
    ]
  },
  dashboardMenu: isDashboardRouteAllowed ? {
    icon: <DashboardIcon />,
    tooltipText: "Dashboard",
    ariaLabel: "dashboard menu",
    testId: "dashboard-dropdown",
    className: "icon-header-menu-button me-2",
    dropdownOpenClassName: "dropdown-open-state",
    dropdownItems: [
      {
        key: "1",
        eventKey: "1",
        ariaLabel: "dashboard",
        dataTestId: "dashboard-dropdown-item",
        onClick: () => navigate("/dashboard"),
        icon: <DashboardIcon />,
        text: "Dashboard",
        role: "menuitem",
        component: "button",
      }
    ]
  } : undefined,
  helpOptionMenu: {
    icon: <HelpIcon className="help-icon" />,
    tooltipText: "Help Options",
    ariaLabel: "help options",
    testId: "help-options-dropdown",
    className: "icon-header-help-menu-button me-2",
    dropdownOpenClassName: "dropdown-open-state",
    dropdownItems: [
      {
        key: "1",
        eventKey: "1",
        ariaLabel: "Please provide feedback",
        dataTestId: "feedback-dropdown-item",
        href: "https://forms.office.com/r/dmuUbwG0pQ",
        target: "_blank",
        icon: <img src={icon_chat} alt="feedback icon" />,
        text: "Please provide feedback",
        role: "menuitem",
      },
      {
        key: "2",
        eventKey: "2",
        ariaLabel: "Raise an issue",
        dataTestId: "contact-service-now-dropdown-item",
        href: "https://informa.service-now.com/iportal?id=sc_cat_item&sys_id=5284d3be1b9a71148ffddb5be54bcb2a",
        target: "_blank",
        icon: <img src={icon_app} alt="serviceNow" />,
        text: "Raise an issue",
        role: "menuitem",
      },
      {
        key: "3",
        eventKey: "3",
        ariaLabel: "Email us a question",
        dataTestId: "report-something-now-dropdown-item",
        href: "mailto:elysia-feedback@informa.com",
        icon: <img src={icon_flag} alt="report" />,
        text: "Email us a question",
        role: "menuitem",
      },
      {
        key: "4",
        eventKey: "4",
        ariaLabel: "How to guides",
        dataTestId: "help-docs-now-dropdown-item",
        href: "https://informaplc.sharepoint.com/teams/Elysia/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FElysia%2FShared%20Documents%2FHelp%20Docs&viewid=ea9f5608%2Da8ea%2D40b9%2D8089%2D27145c3178cf",
        target: "_blank",
        icon: <HelpDocsIcon />,
        text: "How to guides",
        role: "menuitem",
      }
    ]
  },
  logoutMenu: {
    icon: <div className="user-name-initials">{initials}</div>,
    tooltipText: "User Menu",
    ariaLabel: initials,
    testId: "user-credentials-dropdown",
    isUserInitials: true,
    className: "user-name-icon me-3",
    dropdownOpenClassName: "logout-dropdown-open-state",
    dropdownItems: [
      {
        key: "1",
        eventKey: "1",
        ariaLabel: "view profile",
        dataTestId: "view-profile-dropdown-item",
        onClick: () => navigate("/myprofile"),
        icon: <img src={icon_profile_menu} alt="profile" style={{ width: "16px", height: "16px" }} />,
        text: "View Profile",
        role: "menuitem",
        component: "button",
      },
      {
        key: "2",
        eventKey: "2",
        ariaLabel: "notification centre",
        dataTestId: "view-notifications",
        onClick: () => navigate("/notifications"),
        icon: <BellIcon />,
        text: "Notifications",
        role: "menuitem",
        component: "button",
      },
      ...(isSandboxRouteAllowed ? [{
        key: "3",
        eventKey: "3",
        ariaLabel: "sandbox",
        dataTestId: "view-sandbox-dropdown-item",
        onClick: () => navigate("/sandbox"),
        icon: <SandboxIcon />,
        text: "Sandbox",
        role: "menuitem",
        component: "button",
      }] : []),
      {
        key: "4",
        eventKey: "4",
        ariaLabel: "logout",
        dataTestId: "logout-dropdown-item",
        onClick: logout,
        icon: <LogoutIcon />,
        text: "Log Out",
        role: "menuitem",
        component: "button",
      }
    ].filter(Boolean)
  }
});

