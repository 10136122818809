import axios from "axios";
import { catchError } from "./common";

export const getMyNotifications = async (userId): Promise<any> => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_DOMAIN}v1/ai/notifications/users/${userId}`
        );
        return response.data;
    } catch (e: any) {
        console.error(e);
        catchError(e);
        return { "notifications": [] }
    }
}

export const updateNotificationReadStatus = async (userId, notificationId): Promise<any> => {
    try {
        // hardcode the status to read as it will be always to mark read from the UI
        const data = {
            userId,
            status: "read"
        }
        const response = await axios.patch(`${process.env.REACT_APP_API_DOMAIN}v1/ai/notifications/${notificationId}`, data);
        return response.data;
    } catch (e: any) {
        console.error(e);
        catchError(e);
        let retObj: any;
        return retObj;
    }
}
