import { FC, useRef, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import { AuthProvider } from "../providers/authProvider";
import { SettingProvider } from "../providers/settingProvider";
import { ChatProvider } from "../providers/chatProvider";
import { AppProvider } from "../providers/appProvider";

import Layout from "../layout/Layout";

import Home from "../pages/Home";
import MyProfilePage from "../pages/MyProfilePage";
import ManageContentPage from "../pages/ManageContentPage";
import ChatWidgetGenerator from "../pages/ChatWidgetGenerator";
import SandboxPage from "../pages/SandboxPage";
import NotificationsPage from "../pages/NotificationsPage";
import { HistoryProvider } from "../providers/historyProvider";
import Dashboard from "../pages/Dashboard";
import ProtectedRoute from "./ProtectedRoute";

import "../index.scss";

export const AppRoutes: FC = () => {
  const [user, setUser] = useState<AuthContextStore>();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleFocusOfSkipToContentAnchor = () => {
    if (linkRef.current) {
      linkRef.current.classList.add("skip-link-visible");
    }
  };

  const handleBlurOfSkipToContentAnchor = () => {
    if (linkRef.current) {
      linkRef.current.classList.remove("skip-link-visible");
    }
  };

  const handleClickOnSelectSkipToContent = () => {
    if (contentRef.current) {
      contentRef.current.focus();
      if (linkRef.current) {
        linkRef.current.classList.remove("skip-link-visible");
      }
    }
  };

  const handleKeyDownOnSkipToContent = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleClickOnSelectSkipToContent();
    }
  };

  return (
    <BrowserRouter>
      <AuthProvider user={user} setUser={setUser}>
        <AppProvider user={user}>
          <SettingProvider user={user}>
            <ChatProvider user={user}>
              <HistoryProvider user={user}>
                <>
                  <a
                    href="#main-content"
                    ref={linkRef}
                    className="skip-link"
                    onFocus={handleFocusOfSkipToContentAnchor}
                    onBlur={handleBlurOfSkipToContentAnchor}
                    onClick={handleClickOnSelectSkipToContent}
                    onKeyDown={handleKeyDownOnSkipToContent}
                  >
                    Skip to main content
                  </a>
                  <Layout>

                    <Routes>
                      <Route path="/" element={<Home contentRef={contentRef} />} />
                      <Route
                        path="/myprofile"
                        element={<MyProfilePage contentRef={contentRef} />}
                      />
                      <Route
                        path="/mycontent"
                        element={<ManageContentPage contentRef={contentRef} />}
                      />
                      <Route
                        path="/generate"
                        element={<ChatWidgetGenerator user={user} />}
                      />
                      <Route
                        path="/notifications"
                        element={<NotificationsPage user={user} />}
                      />
                      <Route
                        path='/sandbox'
                        element={
                          <ProtectedRoute>
                            <SandboxPage user={user} />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/session/:sessionid"
                        element={<Home contentRef={contentRef} />}
                      />
                      <Route
                        path='/dashboard'
                        element={
                          <ProtectedRoute>
                            <Dashboard user={user} />
                          </ProtectedRoute>
                        }
                      />
                    </Routes>

                  </Layout>
                </>
              </HistoryProvider>
            </ChatProvider>
          </SettingProvider>
        </AppProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
