import { useContext, useEffect, useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { HistoryDataContext } from '../../context/HistoryContext';
import { ChatHistoryDisplay } from './ChatHistoryDisplay';
import { CustomAccordionHeader } from './CustomAccordionHeader';
import { ChatContext } from "../../context/ChatContext";
import { getActiveChatHistoryRecord } from "../../services/history";

export const ChatHistoryComponent = (props) => {
    const chatHistory = useContext(HistoryDataContext);
    const { pathSession_id, setPathSession_id } = props;
    const { setIsChatActive, setNewChat, setChatSessionId, setClearState, setChatAgent, setActiveChatHistoryRecord, setSources, setSelectedSources, setContext, setCollectionName, setBackToChatSession, backToChatSession, sessionPath, allowedCollections, setCollectionType, setCollectionProductLine, setCollection, setIsActiveChatHistoryLoading, isNewSession, setSessionPath, setIsRestrictedChatSessionStatus, isPrivateChat, setShowScrollIcon, setUploadedFileTitle, setUploadedFile, setShowFileToasts } = useContext(ChatContext);
    const navigate = useNavigate();
    const todayAccordionRef = useRef<HTMLDivElement>(null);
    const prevTodayLengthRef = useRef<number | null>(null);

    // added useEffect to expand and scroll to the today's bucket while adding a new chat to the bucket.
    useEffect(() => {
        if (prevTodayLengthRef.current !== null && chatHistory?.today.length > prevTodayLengthRef.current) {
            if (todayAccordionRef.current) {
                todayAccordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            var headerId = "todayAccordion";
            var header = document.getElementById(headerId);
            var button = header?.querySelector("button");
            if (button?.className === "accordion-button collapsed") {
                button?.click();
            }
        }
        prevTodayLengthRef.current = chatHistory?.today.length;
    }, [chatHistory?.today.length]);


    useEffect(() => {
        if (pathSession_id !== "" && (!isNewSession || (sessionPath !== "" && backToChatSession))) {
            fetchHistoryRecord(pathSession_id);
            setBackToChatSession?.(false);
        }
        //eslint-disable-next-line
    }, [pathSession_id]);

    const startNewTopicChat = () => {
        setShowScrollIcon?.(false);
        setClearState?.(true);
        setNewChat?.(false);
        setChatAgent?.("Generic");
        setUploadedFileTitle?.("");
        setUploadedFile?.(null);
        setSources?.([]);
        setSelectedSources?.([]);
        setShowFileToasts?.(false);
    };

    const handleActiveChatHistoryLoad = (session_id, responseData) => {
        navigate(`/session/${session_id}`);
        setSessionPath?.(`/session/${session_id}`);
        let metadata = responseData?.metadata;
        if (metadata && metadata.chat_agent === "Document") {
            let collectionName = metadata.display_name;
            setChatAgent?.("Document");
            if (metadata.document_source.length) {
                setSources?.(metadata.document_source);
                setSelectedSources?.(metadata.document_source);
            } else {
                setSources?.([]);
                setSelectedSources?.([]);
            }
            if (metadata.context) {
                let filteredCollection = allowedCollections?.filter(obj => {
                    return obj.cname === collectionName && obj.perm === "filter";
                }) || [];
                setCollectionName?.(collectionName);
                if (filteredCollection[0]) {
                    setContext?.(filteredCollection[0].ckey);
                    setCollection?.(filteredCollection[0].ckey);
                    setCollectionType?.(filteredCollection[0].ctype);
                    setCollectionProductLine?.(filteredCollection[0].cline);
                }
            } else {
                setCollectionName?.("My Private Collection");
                setCollectionType?.("private");
                setCollection?.("myContentsOnly");
                setContext?.("myContentsOnly");
            }
        } else {
            setChatAgent?.("Generic");
        }
        setActiveChatHistoryRecord(responseData);
        setChatSessionId?.(responseData?.session_id)
    }

    const fetchHistoryRecord = (session_id) => {
        startNewTopicChat();
        let response;
        setIsActiveChatHistoryLoading?.(true);
        (async () => {
            try {
                response = await getActiveChatHistoryRecord(session_id);
                if (response.status === 403 || response.status === 404) {
                    setIsRestrictedChatSessionStatus?.(response.status);
                } else if (response[0]?.history) {
                    setIsRestrictedChatSessionStatus?.("");
                    handleActiveChatHistoryLoad(session_id, response[0]);
                    setIsChatActive?.(true);
                }
            } catch (err) {
                console.log("error fetching chat record");
            } finally {
                setIsActiveChatHistoryLoading?.(false);
            }
        })();
    }

    return (
        <Accordion className="custom-accordion" defaultActiveKey={['0', '4']} alwaysOpen>
            {chatHistory?.today.length > 0 && (
                <Accordion.Item eventKey="0" ref={todayAccordionRef}>
                    <CustomAccordionHeader disabled={isPrivateChat ? true : false} isOpen={true} eventKey="0" testId="today-accordion-header" id="todayAccordion">
                        <div className='custom-accordion-header-text'>Today</div>
                    </CustomAccordionHeader>
                    <Accordion.Body className='custom-accordion-body'>
                        {chatHistory.today.map((chat, index) => (
                            <ChatHistoryDisplay data={chat} key={index} fetchHistoryRecord={() => { setPathSession_id(chat.session_id) }} />
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            )}

            {chatHistory?.yesterday.length > 0 && (
                <Accordion.Item eventKey="1">
                    <CustomAccordionHeader disabled={isPrivateChat ? true : false} eventKey="1" testId="yesterday-accordion-header">
                        <div className='custom-accordion-header-text'>Yesterday</div>
                    </CustomAccordionHeader>
                    <Accordion.Body className='custom-accordion-body'>
                        {chatHistory.yesterday.map((chat, index) => (
                            <ChatHistoryDisplay data={chat} key={index} fetchHistoryRecord={() => { setPathSession_id(chat.session_id) }} />
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            )}

            {chatHistory?.last_7_days.length > 0 && (
                <Accordion.Item eventKey="2">
                    <CustomAccordionHeader disabled={isPrivateChat ? true : false} eventKey="2" testId="last-7-days-accordion-header">
                        <div className='custom-accordion-header-text'>Last 7 days</div>
                    </CustomAccordionHeader>
                    <Accordion.Body className='custom-accordion-body'>
                        {chatHistory.last_7_days.map((chat, index) => (
                            <ChatHistoryDisplay data={chat} key={index} fetchHistoryRecord={() => { setPathSession_id(chat.session_id) }} />
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            )}

            {chatHistory?.last_30_days.length > 0 && (
                <Accordion.Item eventKey="3">
                    <CustomAccordionHeader disabled={isPrivateChat ? true : false} eventKey="3" testId="last-30-days-accordion-header">
                        <div className='custom-accordion-header-text'>Last 30 days</div>
                    </CustomAccordionHeader>
                    <Accordion.Body className='custom-accordion-body'>
                        {chatHistory.last_30_days.map((chat, index) => (
                            <ChatHistoryDisplay data={chat} key={index} fetchHistoryRecord={() => { setPathSession_id(chat.session_id) }} />
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            )}

            {chatHistory?.archived_chats.length > 0 && (
                <Accordion.Item eventKey="4">
                    <CustomAccordionHeader disabled={isPrivateChat ? true : false} isOpen={true} eventKey="4" testId="archive-accordion-header">
                        <div className='custom-accordion-header-text'>Archived Chats</div>
                    </CustomAccordionHeader>
                    <Accordion.Body className='custom-accordion-body'>
                        {chatHistory.archived_chats.map((chat, index) => (
                            <ChatHistoryDisplay data={chat} key={index} fetchHistoryRecord={() => { setPathSession_id(chat.session_id) }} />
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            )}
        </Accordion>
    );
};
