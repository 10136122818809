import { createContext } from 'react';

const initialAppContext: AppContextStore = {
    isSideNavOpen: false,
    isTouchDevice: false,
    unreadNotificationsCount: 0,
    setIsSideNavOpen: () => { },
    setIsTouchDevice: () => { },
    setUnreadNotificationsCount: () => { },
};

export const AppContext = createContext<AppContextStore>(initialAppContext);