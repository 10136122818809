import MyNotifications from "../components/notification/MyNotification";

const NotificationsPage = ({ user }) => {

    return (
        <>
            <div id="main-content" className="notifications-page content-scrollable overflow-auto flex-grow-1 d-flex justify-content-center me-2 mt-4 mb-5">
                <div className="width-control">
                    <MyNotifications user={user} />
                </div>
            </div>
        </>
    )
};

export default NotificationsPage;
