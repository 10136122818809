import React from "react";
import { Button, Form } from "react-bootstrap";
import { ReactComponent as IconBack } from "../../assets/icon-back.svg";

interface OptInSettingsProps {
    profileInfo: any;
    setPageState: (state: string) => void;
    setOptInSetting: (val: string) => Promise<void>;
}

const OptInSettings = ({ profileInfo, setPageState, setOptInSetting }: OptInSettingsProps) => {
    return (
        <>
            <div className="d-flex align-items-center mb-4 optin-header">
                <Button className="back-to-profile" aria-label="Back to Profile" data-testid="back-to-profile" onClick={() => {
                    setPageState("all");
                }}>
                    <IconBack />
                </Button>
                <h1 data-testid="optin-title" className="title1 my-profile-title mb-0">Opt-in</h1>
            </div>

            <p data-testid="optin-description" className="mb-4">
                To allow Elysia searching information about yourself, toggle on the Opt-In function. This will allow Elysia to provide accurate information about you using internal sources (like information uploaded to Elysia) or external sources (such as your LinkedIn account).  If you do not Opt-In your name will generally not appear in Elysia's outputs. You can Opt-Out from this feature at anytime
            </p>
            <div className="menu-option-box p-4 mb-5">
                <p data-testid="select-optin" className="mb-3">Select Opt In</p>
                <Form className="optin-form">
                    <Form.Check aria-label="yes" defaultChecked={profileInfo?.optOut ? false : true} onClick={() => setOptInSetting("Yes")} type="radio" name="optIn" label="Yes"></Form.Check>
                    <Form.Check aria-label="no" defaultChecked={profileInfo?.optOut ? true : false} onClick={() => setOptInSetting("No")} type="radio" name="optIn" label="No"></Form.Check>
                </Form>
            </div>
        </>
    );
};

export default OptInSettings;
