import { Row, Col } from "react-bootstrap";
import DeleteModal from "./DeleteModal";

import icon_loading from "../../assets/elysia-loader.gif"

import "./style.scss";

const ContentRow = (props) => {
    const { collectionItem, deleteSourceById } = props;

    return (
        <Row>
            <Col lg={6} className="text-break">
                <div>{collectionItem?.title}</div>
            </Col>
            <Col lg={3}>
                <div>{collectionItem?.updated_on}</div>
            </Col>
            <Col className="delete-icon-wrapper">
                <img
                    style={{ visibility: "hidden" }}
                    id={collectionItem?.id}
                    src={icon_loading}
                    alt="loading"
                />
                <DeleteModal
                    deleteSourceById={deleteSourceById}
                    documnetID={collectionItem?.id}
                />
            </Col>
        </Row>
    );
};

export default ContentRow;
