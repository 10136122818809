import { toast, ToastOptions, ToastPosition } from 'react-toastify';
import CustomToast from '../components/common/UI/CustomToast';

interface NotifyOptions extends ToastOptions {
    message: string;
    type: 'success' | 'error';
    testId?: string | "";
}

const defaultOptions: ToastOptions = {
    autoClose: 3000,
    position: toast.POSITION.TOP_CENTER as ToastPosition,
    type: 'info',
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    theme: "light",
    icon: false,
    closeButton: false
};

const notify = ({ message, type, ...options}: NotifyOptions) => {
    const toastOptions: ToastOptions = {
        ...defaultOptions,
        ...options,
        type,
    }
    toast(<CustomToast message={message} type={type}/>, toastOptions)
}

export default notify;