import React from 'react';
import parse from 'html-react-parser';

interface TableCellProps {
    as?: 'td' | 'th';
    children?: React.ReactNode;
}

const TableCell = ({ as: Component = 'td', children, ...props }: TableCellProps) => {
    const containsHTML = (children: React.ReactNode): boolean => {
        const HTML_REGEX = /<[^>]+>/g;

        if (Array.isArray(children)) {
            return children.some((child) => typeof child === "string" && HTML_REGEX.test(child));
        }
        if (typeof children === "string") {
            return HTML_REGEX.test(children);
        }
        return false;
    };

    return (
        <Component {...props}>
            {containsHTML(children)
                ? parse(Array.isArray(children) ? children.join("") : children?.toString() ?? "")
                : children}
        </Component>
    );
};

export default TableCell;