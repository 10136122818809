import React, { useEffect, useMemo, useRef, useState } from "react";
import CopyToClipboard from "react-copy-html-to-clipboard";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Card from "react-bootstrap/Card";
import { Button, Form, ListGroup, Collapse } from "react-bootstrap";
import "./style.scss";

import icon_elysia_brain from "../../assets/icon-elysia-brain.svg";
import elyisa_logo from "../../assets/Elysia-logo.svg";
import CodeBlock from "./CodeBlock";
import TableCell from "./TableCell";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faArrowUpRightFromSquare, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as LikeUnselectedIcon } from "../../assets/icon-google-thumbsup-unselected.svg";
import { ReactComponent as DislikeUnselectedIcon } from "../../assets/icon-google-thumbsdown-unselected.svg";
import { ReactComponent as CopyUnselectedIcon } from "../../assets/icon-content copy-unselected.svg";
import { ReactComponent as TickIcon } from "../../assets/icon-google-tick.svg";
import { ReactComponent as CloseIcon } from "../../assets/icon-close.svg";

import { ChatSources } from "../common/ChatSources";
import { logCommentFeedback, logFeedback } from "../../services/feedback";
import { convertMarkdownToPlain, formatMarkdown } from '../../utils/chat';
import { getFavicon } from "../../utils/url";


const ChatSystemMessage = (props) => {
    const [feedback, setFeedback] = useState(null);
    const [showBadge, setShowBadge] = useState(false);
    const [showSource, setShowSource] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [mouseOverIndex, setmouseOverIndex] = useState<number | null>(null);
    const [feedbackText, setFeedbackText] = useState("");
    const badgeRef = useRef<HTMLDivElement>(null);
    const feedbackModalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const value = props?.message?.feedback?.key || null;
        setFeedback(value);
    }, [props?.message?.feedback]);

    useEffect(() => {
        badgeRef?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }, [showBadge])

    useEffect(() => {
        if (showFeedbackModal && feedbackModalRef.current) {
            feedbackModalRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [showFeedbackModal]);

    const handleCopyMessage = () => {
        setShowBadge(true);
        const timeoutRef = setTimeout(() => {
            setShowBadge(false);
            clearTimeout(timeoutRef);
        }, 2000);
    };

    const handleFeedback = async (e, runId, type) => {
        if (feedback === type) {
            setFeedback(null);
            if (runId) {
                await logFeedback(runId, "");
                await logCommentFeedback(props?.message?.runId, "", "");
            }
        } else {
            setFeedback(type);
            setShowFeedbackModal(true);
            if (runId) await logFeedback(runId, type);
        }
    };

    const handleClose = () => {
        setShowFeedbackModal(false);
        setFeedbackText("");
    };

    const handleSubmitFeedback = async () => {
        const feedbackType = feedback === null ? "" : feedback;
        if (props?.message?.runId) {
            await logCommentFeedback(props?.message?.runId, feedbackType, feedbackText);
            handleClose();
        }
    }

    const toolCall = useMemo(() => {
        const tCall = props?.message?.toolCall;
        if (tCall) {
            return tCall !== "Document" ? `Searching: ${tCall}` : tCall;
        }
    }, [props?.message?.toolCall]);

    const toolResults = useMemo(() => {
        if (props?.message?.toolResult?.length) {
            return props?.message?.toolResult;
        }
        // eslint-disable-next-line
    }, [props?.message?.toolResult?.length]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            setShowSource((prev) => !prev) // Trigger click behavior
        }
    };

    return (
        <>
            <div className="sys-msg-body mb-4">
                <Card className="sys-msg-card shadow-sm bg-white rounded response-card">
                    <Card.Body className="custom-sys-card-body" data-testid="response-prompt-body">
                        <Card.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <img
                                        className="sys-msg-card-img"
                                        data-testid="sys-msg-elysia-image"
                                        src={elyisa_logo}
                                        alt="Elysia"
                                    />
                                </div>
                                {!props?.message?.body && !toolResults?.length && toolCall ? (
                                    <div className="searching-placeholder">
                                        {toolCall !== "Document" ? (
                                            <span>{toolCall}</span>
                                        ) : (
                                            <span>Retrieving information</span>
                                        )}
                                    </div>
                                ) : null}
                                {toolResults?.length ? <div>
                                    <div
                                        tabIndex={0}
                                        className="source-interaction-btn"
                                        onClick={() => setShowSource((prev) => !prev)}
                                        onKeyUp={(e) => handleKeyPress(e)}
                                    >
                                        Searched {toolResults.length - 1} sites
                                        &nbsp;
                                        <FontAwesomeIcon icon={showSource ? faAngleUp : faAngleDown} />
                                    </div>
                                </div> : null}
                            </div>
                        </Card.Title>
                        <Collapse in={showSource} timeout={1000}>
                            <div className="pb-4">
                                <ListGroup>
                                    {toolResults && toolResults.map((sObj, inx) => {
                                        if (sObj?.toolcall) {
                                            const searchURL = encodeURI(`https://www.google.com/search?q=${sObj?.searching}`)
                                            return (
                                                <ListGroup.Item
                                                    key={`searching_${inx}`}
                                                    as="a"
                                                    active={false}
                                                    href={searchURL}
                                                    target="_blank"
                                                    className="d-flex justify-content-between align-items-start source-list-item"
                                                    onMouseEnter={() => setmouseOverIndex(inx)}
                                                    onMouseLeave={() => setmouseOverIndex(null)}
                                                    title={searchURL}
                                                >
                                                    <div className="d-flex align-items-center source-item-container">
                                                        <FontAwesomeIcon className="source-img" icon={faMagnifyingGlass} />
                                                        <div className="source-item">
                                                            "{sObj.searching}"
                                                        </div>
                                                    </div>
                                                    {mouseOverIndex === inx && (<FontAwesomeIcon icon={faArrowUpRightFromSquare} />)}
                                                </ListGroup.Item>
                                            )
                                        }
                                        const url = new URL(sObj.source);
                                        return (
                                            <ListGroup.Item
                                                key={`${url.hostname}_${inx}`}
                                                as="a"
                                                active={false}
                                                href={sObj.source}
                                                target="_blank"
                                                className="d-flex justify-content-between align-items-start source-list-item"
                                                onMouseEnter={() => setmouseOverIndex(inx)}
                                                onMouseLeave={() => setmouseOverIndex(null)}
                                                title={sObj.source}
                                            >
                                                <div className="d-flex align-items-center source-item-container">
                                                    <img
                                                        alt={url.hostname}
                                                        className="source-img"
                                                        src={getFavicon(url)}
                                                        aria-hidden="true"
                                                    />
                                                    <div className="source-item">
                                                        {sObj.source}
                                                    </div>
                                                </div>
                                                {mouseOverIndex === inx && (<FontAwesomeIcon icon={faArrowUpRightFromSquare} />)}
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            </div>
                        </Collapse>
                        <Card.Text
                            className="sys-msg-card-desc overflow-x-auto"
                        >
                            <ReactMarkdown
                                children={formatMarkdown(props?.message?.body)}
                                remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                                components={{
                                    table: ({ node, ...props }) => (
                                        <div className="markdown-table-container overflow-x-auto pe-2">
                                            <table className="markdown-table" {...props} />
                                        </div>
                                    ),
                                    td: (props) => <TableCell {...props} />,
                                    th: (props) => <TableCell as="th" {...props} />,
                                    code: CodeBlock,
                                    a: ({ node, ...props }) => (
                                        <a href={props.href} target="_blank" rel="noopener noreferrer">
                                            {props.children}
                                        </a>
                                    ),
                                }} />
                        </Card.Text>
                        {props?.message?.references && props?.message?.references.length > 0 && (
                            <ChatSources references={props?.message?.references} />
                        )}

                    </Card.Body>

                    {(props?.message?.showInteractions || props.historySystemMessage) && (
                        <div className="sys-msg-card-interactions position-relative">
                            <div className="text-end">
                                <span className="me-3 feedback-line " data-testid="share-feedback-label">Share Feedback : </span>
                                <Button
                                    aria-label="like"
                                    className={feedback === 'thums_up' ? "chat-action-button me-1 like-button" : "unselected-chat-action-button me-1 like-button"}
                                    data-testid="like-button"
                                    onClick={(e) => { handleFeedback(e, props?.message?.runId, 'thums_up') }}
                                >
                                    <LikeUnselectedIcon className={feedback === 'thums_up' ? "like-icon" : "unselected-like-icon"} />
                                </Button>

                                <Button
                                    aria-label="dislike"
                                    className={feedback === 'thums_down' ? "chat-action-button me-1 dislike-button" : "unselected-chat-action-button me-1 dislike-button"}
                                    data-testid="dislike-button"
                                    onClick={(e) => { handleFeedback(e, props?.message?.runId, 'thums_down') }}
                                >
                                    <DislikeUnselectedIcon className={feedback === 'thums_down' ? "dislike-icon" : "unselected-dislike-icon"} />
                                </Button>
                                <CopyToClipboard
                                    text={() => convertMarkdownToPlain(props?.message?.body)}
                                >
                                    <Button
                                        aria-label="copy"
                                        className="unselected-chat-action-button copy-button"
                                        data-testid="copy-button"
                                        onClick={handleCopyMessage}
                                    >
                                        <CopyUnselectedIcon />
                                    </Button>
                                </CopyToClipboard>

                            </div>
                            {showBadge && (
                                <span ref={badgeRef} className="history-badge-for-copy-button position-absolute">
                                    <TickIcon />
                                    <span>Copied</span>
                                </span>
                            )}
                        </div>
                    )}

                </Card>

                {showFeedbackModal && (
                    <div ref={feedbackModalRef} className="feedback-for-responses" data-testid="feedback-card">
                        <div className="feedback-header">
                            <div className="feedback-text" data-testid="feedback-title">Feedback</div>
                            <Button aria-label="close feedback" className="close-feedback" data-testid="close-feedback" onClick={() => { handleClose() }}><CloseIcon /></Button>
                        </div>
                        <div className="feedback-message">
                            <img
                                className="sys-msg-card-img"
                                data-testid="feedback-elysia-icon"
                                src={icon_elysia_brain}
                                alt="Elysia icon"
                            />
                            <div className="feedback-content-text" data-testid="feedback-sub-title">Your feedback is much appreciated, why have you chosen this rating?</div>
                        </div>
                        <Form.Control
                            className="inputForFeedback"
                            placeholder="Additional feedback..."
                            data-testid="feedback-text-field"
                            value={feedbackText}
                            onChange={(e) => setFeedbackText(e.target.value)}
                            autoComplete="off"
                        />
                        <Button type="submit" disabled={feedbackText === ""} className="submit-button" aria-label="submit feedback" data-testid="submit-feedback" onClick={() => { handleSubmitFeedback() }}><div className="submit-text">Submit</div></Button>
                    </div>
                )}
            </div>
        </>
    );
};

export default ChatSystemMessage;