import React, { useEffect } from 'react';
import { ReactComponent as IconTick } from '../../../assets/icon_tick.svg';
import { ReactComponent as IconBell } from '../../../assets/icon_bell.svg'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FILE_UPLOAD_ERROR_ID, FILE_UPLOAD_ACCEPTED_ID, PROFILE_INCOMPLETE_TOAST_ID, FILE_UPLOAD_TYPE_ID, FILE_UPLOAD_FAIL_ID, FILE_UPLOAD_SIZE_ID, CHAT_DISABLED_ID } from '../../../config/settings';
import { Button } from 'react-bootstrap';
import { ReactComponent as IconClose } from '../../../assets/icon-close.svg';

type CustomToastProps = {
    message: string;
    type: 'success' | 'error';
    closeToast?: () => void;
};

const iconTypes = {
    success: <IconTick data-testid="success-toast-icon" style={{ color: '#33D27E' }} />,
    error: <IconBell data-testid="error-toast-icon" style={{ color: '#CC31C2' }} />
}

const CustomToast = ({ message, type, closeToast }: CustomToastProps) => {

    const location = useLocation();

    /**
     * incomplete profile toast is displayed on the landing page which is not an autoclose toast.
     * Below code makes sure that the toast is dismissed when user navigates to other page. The if
     * condition is needed so that the toast is not dismissed in the home page itself. 
     */
    useEffect(() => {
        const { pathname } = location;
        if (pathname !== '/' && !pathname.includes('/session')) {
            toast.dismiss(PROFILE_INCOMPLETE_TOAST_ID);
            toast.dismiss(FILE_UPLOAD_ERROR_ID);
            toast.dismiss(FILE_UPLOAD_ACCEPTED_ID);
            toast.dismiss(FILE_UPLOAD_TYPE_ID);
            toast.dismiss(FILE_UPLOAD_FAIL_ID);
            toast.dismiss(FILE_UPLOAD_SIZE_ID);
            toast.dismiss(CHAT_DISABLED_ID);
        }
    }, [location]);

    return (
        <div data-testid="toast-container" className='custom-toast-container d-flex justify-content-between'>
            {iconTypes[type]}
            <span data-testid="toast-message" dangerouslySetInnerHTML={{ __html: message }}></span>
            <Button data-testid="dismiss-toast" aria-label="close-toast" onClick={closeToast}>
                <IconClose />
            </Button>
        </div>
    );
};

export default CustomToast;
