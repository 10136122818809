import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ReactComponent as IconBack } from "../../assets/icon-back.svg";

interface SocialNetworksProps {
    profileInfo: any;
    setPageState: (state: string) => void;
    processUpdateProfile: () => void;
    linkedinUrl: string;
    setLinkedinUrl: (url: string) => void;
}

const SocialNetworks = ({ profileInfo, setPageState, processUpdateProfile, linkedinUrl, setLinkedinUrl }: SocialNetworksProps) => {
    const { register, handleSubmit } = useForm();

    return (
        <>
            <div className="d-flex align-items-center mb-4 optin-header">
                <Button className="back-to-profile" aria-label="Back to Profile" data-testid="back-to-profile" onClick={() => {
                    setPageState("all");
                }}>
                    <IconBack />
                </Button>
                <h1 data-testid="social-title" className="title1 my-profile-title mb-0">Social Networks</h1>
            </div>
            <p data-testid="social-description" className="mb-4">
                You can add your social media accounts (e.g. LinkedIn) to improve the accuracy and quality of the responses about yourself. If you sync your account, Elysia will consult the linked account to enhance some sections of your profile (eg completing fields like "About", "Skills" and "Interests"), and it will consult the information in your synced account where necessary to improve responses about yourself or where your expertise matches with the object of the request made by another colleague. You can remove the synced account at any time and Elysia will no longer retrieve information from the synced account
            </p>
            <div className="menu-option-box p-4 mb-5">
                <p data-testid="social-networks" className="mb-3">Social Networks</p>
                <div className="social-selection-container d-flex align-items-center">
                    <span data-testid="linkedin-row">LinkedIn</span>
                    <div className="flex-grow-1">
                        <Form onSubmit={handleSubmit(processUpdateProfile)}>
                            <Form.Group controlId="linkedin-url">
                                <Form.Control {...register("linkedin-url")} type="text" data-testid="linkedin-url-input" aria-label={linkedinUrl} value={linkedinUrl} onChange={(event) => setLinkedinUrl(event.target.value)} />
                            </Form.Group>
                            <Button type="submit" aria-label="Update Profile" data-testid="update-profile" className="update-profile light-with-border">
                                Update profile
                            </Button>
                        </Form>
                    </div>
                </div>
                {profileInfo?.linkedin && <div data-testid="linkedin-disclaimer" className="profile-info-message">
                    This account is synced with Elysia
                </div>}
            </div>
        </>
    );
};

export default SocialNetworks;
