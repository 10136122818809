export const CustomDropdownItem = ({ children, className = '', component = 'a', ...props }) => {
  if (component === 'button') {
    return (
      <button
        {...props}
        className={`dropdown-item-anchor-button dropdown-item ${className || ''}`.trim()}
      >
        {children}
      </button>
    );
  } else {
    return (
      <a
        {...props}
        className={`dropdown-item-anchor-button dropdown-item ${className || ''}`.trim()}
      >
        {children}
      </a>
    );
  }
}
