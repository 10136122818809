const PrivateChat = (props) => {
    return (
        <div className="justify-content-center align-items-center d-flex flex-column h-100">
            <div className="title1 private-chat-title" data-testid="private-chat-title">Private Chat</div>
            <div className="text-center private-chat-subtitle mt-3" data-testid="private-chat-sub-title">While in Private Chat no chat history will be recorded, and any prompts and responses used within the chat will not be recorded</div>
        </div>
    );
};

export default PrivateChat;
