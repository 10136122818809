import { ReactComponent as ToggleOnIcon } from "../../assets/icon-toggle-on.svg";
import { ReactComponent as ToggleOffIcon } from "../../assets/icon-toggle-off.svg";

const DocSourceList = (props) => {
    const { collectionSources, getFileName, selectedSources, handleSourceSelect, disableCsv, disableNonCsv } = props;

    const truncate = (str, length) => {
        if (str.length > length) {
            return `${str.substring(0, length)}...`;
        }
        return str;
    };

    return (
        <div className="ps-2 doc-list-wrapper">
            {collectionSources.map((item, index) => (
                <div key={index} className="p-2 d-flex">
                    <label className="switch">
                        <input
                            aria-label={getFileName(item.source)}
                            value={getFileName(item.source)}
                            checked={((selectedSources?.indexOf(getFileName(item.source), 0) === undefined) ? false : (selectedSources?.indexOf(getFileName(item.source), 0) > -1))}
                            type="checkbox"
                            onChange={(e) => {
                                handleSourceSelect(e);
                            }}
                        />
                        <ToggleOnIcon className="icon-toggle-on d-none" />
                        <ToggleOffIcon className="icon-toggle-off" />
                    </label>
                    <span
                        title={item.title}
                        className={`px-2 ${(disableCsv && item.title.toLowerCase().endsWith('.csv')) ||
                            (disableNonCsv && !item.title.toLowerCase().endsWith('.csv'))
                            ? 'disabled'
                            : ''
                            }`}
                    >
                        {truncate(item.title, 120)}
                    </span>
                </div>
            ))}
        </div>
    )
};

export default DocSourceList;
