import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { parse } from 'date-fns';

import { getSources, deleteSource, getContentSignedUrl, uploadFileUsingUrl, triggerIngestion } from "../../services/content";
import { ChatContext } from "../../context/ChatContext";

import { BackToChatButton } from "../common/BackToChatButton";
import notify from "../../services/notify";
import CollectionFilterContainer from "./CollectionFilterContainer";
import UploadFile from "./UploadFile";
import SaveContent from "./SaveContent";
import ContentRow from "./ContentRow";
import ContentRowHeader from "./ContentRowHeader";
import ManageContentHeader from "./ManageContentHeader";

import icon_loading from "../../assets/elysia-loader.gif"
import icon_elysia_brain from "../../assets/icon-elysia-brain.svg";

import "./style.scss";

const ManageContent = () => {
  const [collection, setCollection] = useState("myCollection");
  const [collectionType, setCollectionType] = useState("private");
  const [collectionProductLine, setCollectionProductLine] = useState("");
  const [collectionName, setCollectionName] = useState("My Private Collection")
  const [collectionSources, setCollectionSources] = useState<SourceType[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");
  const { setRefreshSources, setAvailablePrivateDocs } = useContext(ChatContext);
  const [pageState, setPageState] = useState("manage");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileTitle, setUploadedFileTitle] = useState("");

  const { register, setValue } = useForm();

  useEffect(() => {
    setValue("collection-list", collectionType + "|" + collectionProductLine + "|" + collectionName + "|" + collection);
    setValue("search-text", searchTerm);
    // eslint-disable-next-line  
  }, [collection, collectionProductLine, collectionType]);

  useEffect(() => {
    getCollectionSources("myCollection", "", "desc");
    // eslint-disable-next-line  
  }, []);

  const reloadCollectionSources = (a_collection, a_search_term, a_sort_order) => {
    getCollectionSources(a_collection, a_search_term, a_sort_order);
  }

  const onCollectionChange = (e) => {
    setCollectionType(e.target.value.split("|")[0]);
    setCollectionProductLine(e.target.value.split("|")[1]);
    setCollectionName(e.target.value.split("|")[2]);
    setCollection(e.target.value.split("|")[3]);
    reloadCollectionSources(e.target.value.split("|")[3], "", "desc");
    setSearchTerm("");
    setSortOrder("desc");
  }

  const getCollectionSources = async (a_collection, a_search_term, a_sort_order) => {
    (async () => {
      let response;
      try {
        setLoading(true);
        response = a_collection === "myCollection" ? await getSources("true", "", a_search_term, a_sort_order) : await getSources("false", a_collection, a_search_term, a_sort_order);
        setLoading(false);
        setAvailablePrivateDocs?.(response?.map(doc => doc?.title));
      } catch (err) {
        setLoading(false);
      }
      let list: SourceType[] = [];
      response.map((item) => {
        return list.push({ ...item, formattedDate: parse(item.updated_on, 'dd-MMM-yyyy HH:mm', new Date()) });
      });
      setCollectionSources(list);
    })();
  };

  const sortCollectionSources = (direction) => {
    setSortOrder(direction === "desc" ? "asc" : "desc");
    reloadCollectionSources(collection, searchTerm, direction === "desc" ? "asc" : "desc");
  };

  const deleteSourceById = (id) => {
    (async () => {
      const element = document.getElementById(id);
      if (element) { element.style.visibility = 'visible' };
      let response = await deleteSource(id);
      if (response.delete === "success") {
        let list = [...collectionSources]
        list.splice(list.findIndex(item => item.id === id), 1)
        setAvailablePrivateDocs?.(list?.map(item => item?.title.toString()));
        setCollectionSources(list)
        notify({
          message: 'Content is deleted successfully.',
          type: 'success'
        })
        setRefreshSources?.(Math.random());
      }
      if (element) { element.style.visibility = 'hidden' };
    })();
  }

  const uploadFile = async () => {
    try {
      setLoading(true);
      let response = await getContentSignedUrl(collectionType, collectionProductLine, uploadedFile?.name ? uploadedFile?.name : "", uploadedFileTitle === "" && uploadedFile?.name ? uploadedFile?.name : uploadedFileTitle);
      if (uploadedFile && response.url) {
        try {
          await uploadFileUsingUrl(response.url, uploadedFile);
          await triggerIngestion(response.path, (uploadedFileTitle === "" && uploadedFile?.name) ? uploadedFile?.name : uploadedFileTitle);
          setLoading(false);
          setUploadedFileTitle("");
          notify({
            message: 'Your content is being uploaded, you will receive an email when completed. In busy times this can be a longer time.',
            type: 'success'
          });
        } catch (e: any) {
          console.error(e);
          notify({
            message: e?.response?.data?.message,
            type: 'error',
          });
        }
      }
    } catch (e: any) {
      console.error(e);
      notify({
        message: e?.response?.data?.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
      setPageState("manage");
      reloadCollectionSources(collection, searchTerm, "desc");
    }
  }

  return (
    <>
      <BackToChatButton />
      {pageState === "manage" && (
        <>
          <ManageContentHeader setPageState={setPageState} />
          <div className="content-list-wrapper mt-3">
            <div className="content-filter-wrapper">
              <CollectionFilterContainer
                setCollectionSources={setCollectionSources}
                reloadCollectionSources={reloadCollectionSources}
                collection={collection}
                sortOrder={sortOrder}
                register={register}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                onCollectionChange={onCollectionChange} />
              {!loading && collectionSources && collectionSources.length > 0 && (
                <ContentRowHeader
                  sortCollectionSources={sortCollectionSources}
                  sortOrder={sortOrder}
                />
              )}
            </div>
            {loading && (
              <div>
                <div className="loading text-center">
                  <img src={icon_loading} alt="loading" />
                </div>
              </div>
            )}
            {!loading && collectionSources && collectionSources.length === 0 && (
              <>
                <div>  &nbsp;  </div>
                <div className="system-info-msg" style={{ marginTop: "0px" }}>
                  {" "}
                  <img src={icon_elysia_brain} alt="Elysia" /> &nbsp; You have no
                  documents uploaded. Choose a file to upload.{" "}
                </div>
              </>
            )}
            {!loading && collectionSources && collectionSources.length > 0 && (
              <div>
                <div className="content-list">
                  {collectionSources.map((collectionItem, index) => {
                    return (
                      <div key={index} className="content-row">
                        <ContentRow
                          collectionItem={collectionItem}
                          deleteSourceById={deleteSourceById}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {pageState === "upload" && (
        <UploadFile
          register={register}
          setLoading={setLoading}
          setUploadedFile={setUploadedFile}
          setValue={setValue}
          setPageState={setPageState}
          onCollectionChange={onCollectionChange}
          collectionName = {collectionName}
        />
      )}


      {pageState === "save" && (
        <SaveContent
          register={register}
          setPageState={setPageState}
          setLoading={setLoading}
          loading={loading}
          uploadFile={uploadFile}
          collectionName={collectionName}
          setUploadedFileTitle={setUploadedFileTitle}
        />
      )}
    </>
  );
};

export default ManageContent;
