import { useContext, useEffect, useState, useCallback } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";

import { ReactComponent as SavedPromptsIcon } from "../../assets/icon-bookmark.svg";
import { ReactComponent as SearchIcon } from "../../assets/icon-google-search.svg";

import notify from "../../services/notify";
import { AuthContext } from "../../context/AuthContext";
import { getSavedPrompts } from "../../services/savedPrompts";
import { SavedPromptButton } from "./SavedPromptButton";
import OverlayTooltip from "../common/UI/OverlayTooltip";
import { ChatContext } from "../../context/ChatContext";

interface SavedTopic {
    created_on: string;
    prompt: string;
    prompt_id: number;
    user_id: string;
}

interface ChatSavedPromptProps {
    initiated: boolean,
    setInitiated: (boolean) => void;
}

export const ChatSavedPrompt = ({ initiated, setInitiated }: ChatSavedPromptProps) => {
    const user = useContext(AuthContext);
    const [show, setShow] = useState<boolean>(false);
    const [savedTopics, setSavedTopics] = useState<SavedTopic[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredTopics, setFilteredTopics] = useState<SavedTopic[]>([]);
    const [isActive, setIsActive] = useState(false);
    const { isChatDisabled } = useContext(ChatContext)

    const positionModal = useCallback(() => {
        const inputElement = document.getElementById('prompt-input');
        const modalWrapperElement = document.getElementById('saved-prompt-modal');
        const modalElement = modalWrapperElement?.children[0] as HTMLElement | null;

        if (inputElement && modalElement) {
            const inputRect = inputElement.getBoundingClientRect();
            const modalHeight = modalElement.offsetHeight;
            const inputWidth = inputElement.clientWidth;
            const screenWidth = window.innerWidth;

            modalElement.style.position = 'absolute';
            modalElement.style.left = screenWidth < 960 ? `${inputRect.left}px` : `${inputRect.left + 20}px`;
            modalElement.style.top = `${inputRect.top - modalHeight}px`;
            modalElement.style.width = screenWidth < 960 ? `${inputWidth}px` : `576px`;
            modalElement.setAttribute('data-testid', 'saved-prompt-modal');
        }
    }, []);

    useEffect(() => {
        if (show) {
            positionModal();
            window.addEventListener('resize', positionModal);
        }
        return () => {
            window.removeEventListener('resize', positionModal);
        };
        // eslint-disable-next-line
    }, [show, positionModal]);

    useEffect(() => {
        if (initiated) {
            handleGetSavedPrompts();
        }
        // eslint-disable-next-line
    }, [initiated])

    useEffect(() => {
        if (savedTopics.length === 0) {
            handleClose();
        }
        else if (searchQuery !== "") {
            setFilteredTopics(
                savedTopics.filter(topic =>
                    topic.prompt.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        } else {
            setFilteredTopics(savedTopics);
        }
        // eslint-disable-next-line
    }, [searchQuery, savedTopics]);

    const handleGetSavedPrompts = async () => {
        try {
            const response = await getSavedPrompts(user);
            if (response.length > 0) {
                setShow(true);
                setSavedTopics(response);
            } else {
                notify({
                    message: 'You have not yet saved any prompts to display!',
                    type: 'success'
                });
            }
        } catch (err) {
            console.error("error getting saved prompt");
        }
    };

    const handleInitiated = () => {
        setIsActive(prevState => !prevState);
        setInitiated(true);
    };

    const handleClose = () => {
        setIsActive(false);
        setSearchQuery("");
        setShow(false);
        setInitiated(false);
    }

    return (
        <>
            <OverlayTooltip
                tooltipText="Saved Prompts"
                buttonContent={<SavedPromptsIcon />}
                ariaLabel="Saved Prompts"
                testId="saved-prompts"
                tooltipClassName="tooltip-sidebar"
                buttonClassName={`saved-prompts ${isActive ? 'active' : ''}`}
                onClick={handleInitiated}
                placement="top-start"
                disabled={isChatDisabled}
            />
            <Modal
                show={show}
                onHide={handleClose}
                aria-label="save prompt modal"
                dialogClassName="custom-modal"
                className="save-prompt-modal"
                backdropClassName="custom-backdrop"
                centered
                id="saved-prompt-modal"
            >
                <Modal.Header closeButton className="pb-2">
                    <Modal.Title data-testid="saved-prompts-header">Saved prompts</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <InputGroup className="search-bar-container">
                        <div data-testid="search-saved-prompt-button" className="search-saved-topic-button">
                            <SearchIcon />
                        </div>
                        <Form.Control
                            className="search-text"
                            type="text"
                            data-testid="search-prompt-input"
                            placeholder="Search content"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                    <div className="saved-topic-container">
                        <div className="saved-topic-inner-conatiner">
                            {filteredTopics.length === 0 ? (
                                <div className="no-results-message">
                                    No saved prompts matched your search.
                                </div>
                            ) : (
                                filteredTopics.map((topic, index) => (
                                    <SavedPromptButton
                                        topic={topic}
                                        index={index}
                                        setSavedTopics={setSavedTopics}
                                        handleClose={handleClose}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
