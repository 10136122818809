import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { BackToChatButton } from "../common/BackToChatButton";
import { getMyNotifications, updateNotificationReadStatus } from "../../services/notification";
import { ReactComponent as IconBack } from "../../assets/icon-back.svg";
import icon_loading from "../../assets/elysia-loader.gif";
import { AppContext } from "../../context/AppContext";
import { formatMarkdown } from "../../utils/chat";
import './style.scss';

interface Notification {
    id: number;
    message: string;
    status: "read" | "unread";
    title: string;
    category: string;
    created_on: string;
}

const MyNotifications = ({ user }) => {

    const [loading, setLoading] = useState(false);
    const [pageState, setPageState] = useState("all");
    const [notifications, setNotifications] = useState<Notification[]>();
    const [selectedNotification, setSelectedNotification] = useState<Notification>();
    const { setUnreadNotificationsCount } = useContext(AppContext)

    useEffect(() => {
        (async () => {
            let response;
            try {
                setLoading(true);
                response = await getMyNotifications(user.signInUserSession.idToken.payload.sub);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.error("error fetching notifications");
            }
            if (response && response.notifications) {
                setNotifications(response.notifications);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const loadNotification = async (id: number) => {
        setPageState('one');
        const selectedNotification = notifications?.find((notification) => notification.id === id);

        if (!selectedNotification) return;

        setSelectedNotification(selectedNotification);
        if (selectedNotification.status === 'unread') {
            const response = await updateNotificationReadStatus(user.signInUserSession.idToken.payload.sub, id);
            setUnreadNotificationsCount(response.unread_count);

            // Update the notification status in the state
            setNotifications(prevNotifications =>
                prevNotifications?.map(notification =>
                    notification.id === id
                        ? { ...notification, status: 'read' }
                        : notification
                )
            );
        }
    }

    return (
        <>
            <BackToChatButton />

            <div className="d-flex justify-content-between mb-4">
                {pageState === "all" && (<h1 data-testid="notification-title" className="title1 my-notification-title mb-0">My Notifications</h1>)}
                {pageState === "one" && (<h1 data-testid="all-notifications-title" className="title1 my-notification-title mb-0">
                    <Button className="back-to-notifcations" aria-label="Back to Notifications" data-testid="back-to-notifications" onClick={() => {
                        setPageState("all");
                    }}>
                        <IconBack />
                    </Button>
                    All Notifications
                </h1>)}
            </div>

            {
                pageState === "all" && loading && (
                    <>
                        <div className="gap">&nbsp;</div>
                        <div className="text-center">
                            <img src={icon_loading} alt="loading" />
                        </div>
                    </>
                )
            }
            {
                pageState === "all" && !loading && (
                    <>
                        <div className="menu-option-box p-2 mb-4 p-md-4">
                            {notifications?.length === 0 ? (<div> There are no notifications to display</div>) :
                                (notifications?.map(notification => (
                                    <button className="w-100 h-100" onClick={() => { loadNotification(notification.id) }}>
                                        <div className="notification-row">
                                            <p className="notification-info-row d-flex justify-content-between">
                                                <span data-testid="notification-type">{notification.category}</span>
                                                <span data-testid="notification-date">{notification.created_on}</span>
                                            </p>
                                            <div className={`notification-title ${notification.status === "unread" ? 'unread' : ''}`} data-testid="notification-title">
                                                {notification.status === "unread" && (<span className="indicator"></span>)}
                                                {notification.title}
                                            </div>
                                        </div>
                                    </button>
                                )))}
                        </div>
                    </>
                )
            }
            {
                pageState === "one" && selectedNotification && (
                    <>
                        <div className="menu-option-box notification-detail p-4 mb-4">
                            <div className="notification-row">
                                <p className="notification-info-row d-flex justify-content-between">
                                    <span data-testid="notification-type">{selectedNotification.category}</span>
                                    <span data-testid="notification-date">{selectedNotification.created_on}</span>
                                </p>
                                <div className="notification-title" data-testid="notification-title">
                                    {selectedNotification.title}
                                </div>
                                <div className="notification-body" data-testid="notification-body">
                                    <ReactMarkdown
                                        children={formatMarkdown(selectedNotification.message)}
                                        remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                                        components={{
                                            a: ({ node, ...props }) => (
                                                <a href={props.href} target="_blank" rel="noopener noreferrer">
                                                    {props.children}
                                                </a>
                                            ),
                                        }} />
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </>
    );
};

export default MyNotifications;
