import { useState, ReactNode, useContext } from "react";
import { Dropdown, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomDropdownItem } from "./CustomDropdownItem";
import { AppContext } from "../../../context/AppContext";
import "../../home/style.scss";

export interface CommonDropdownProps {
  icon: ReactNode;
  tooltipText: string;
  ariaLabel: string;
  testId: string;
  className: string;
  isUserInitials?: boolean
  dropdownOpenClassName: string;
  align?: string;
  dropdownItems: {
    key: string;
    eventKey: string;
    ariaLabel: string;
    dataTestId: string;
    onClick?: () => void;
    component?: string;
    icon: ReactNode;
    text: string;
    href?: string;
    target?: string;
    role?: string,
  }[];
}


export const CommonDropdown = ({
  icon,
  tooltipText,
  dropdownItems,
  ariaLabel,
  testId,
  isUserInitials,
  className = "",
  dropdownOpenClassName = "",
}: CommonDropdownProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isTouchDevice, unreadNotificationsCount } = useContext(AppContext);

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      setShowTooltip(true);
    }
  }
  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setShowTooltip(false);
    }
  }
  const handleDropdownToggle = (isOpen: boolean) => setDropdownOpen(isOpen);
  const handleFocus = () => setShowTooltip(true);
  const handleBlur = () => setShowTooltip(false);
  const handleTouchStart = () => setShowTooltip(true);
  const handleTouchEnd = () => setShowTooltip(false);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelect = () => {
    setDropdownOpen(false);
  };

  const handleItemClick = (onClick?: () => void) => {
    if (onClick) onClick();
    setDropdownOpen(false);
  };

  return (
    <OverlayTrigger
      placement="bottom-end"
      overlay={<Tooltip className="custom-tooltip">{tooltipText}</Tooltip>}
      show={showTooltip && !dropdownOpen}
    >
      <Dropdown
        as={ButtonGroup}
        align="end"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onToggle={handleDropdownToggle}
        show={dropdownOpen}
        data-testid={testId}
        focusFirstItemOnShow={false}
      >
        <Dropdown.Toggle
          aria-label={ariaLabel}
          aria-haspopup="true"
          className={`${className} ${dropdownOpen ? dropdownOpenClassName : ""}`}
          onClick={handleDropdownClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {icon}
          {(isUserInitials && unreadNotificationsCount > 0) ? <span className="notification-dot" data-testid="notification-dot"></span> : ""}
        </Dropdown.Toggle>
        <Dropdown.Menu role="menu" onClick={handleSelect}>
          {dropdownItems.map((item) => (
            <CustomDropdownItem
              key={item.key}
              href={item.href}
              target={item.target}
              role={item?.role ?? "menuitem"}
              aria-label={item.ariaLabel}
              data-testid={item.dataTestId}
              component={item?.component}
              onClick={() => handleItemClick(item.onClick)}
            >
              <div className="d-flex align-items-center">
                <div className="dropdown-item-icon">{item.icon}</div>
                <div className="ps-3 dropdown-item-text">{item.text} {(item.text === 'Notifications' && unreadNotificationsCount > 0) ? <span className="notification-dot"></span> : ""} </div>
              </div>
            </CustomDropdownItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </OverlayTrigger>
  );
};
