import React, { useState, FC, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { getMyNotifications } from '../services/notification';

interface Props {
    children: JSX.Element,
    user: any
}

export const AppProvider: FC<Props> = ({ children, user }) => {

    const [isSideNavOpen, setIsSideNavOpen] = useState<boolean>(localStorage.getItem('sidebarOpen') === 'true');
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState<number>(0);

    useEffect(() => {
        (async () => {
          let response;
          try {
            response = await getMyNotifications(user.signInUserSession.idToken.payload.sub);
          } catch (err) {
            console.error("error fetching notifications details");
          }
          if (response && response.unread_count ) {
            setUnreadNotificationsCount(response.unread_count);
          }
        })();
        // eslint-disable-next-line
      }, []);

    const appContext: AppContextStore = {
        isSideNavOpen,
        isTouchDevice,
        unreadNotificationsCount,
        setIsSideNavOpen,
        setIsTouchDevice,
        setUnreadNotificationsCount
    };


    return (
        <AppContext.Provider value={appContext}>
            {user ?
                children
                : ''
            }
        </AppContext.Provider>
    )
}