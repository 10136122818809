import { useContext, useEffect, useState, ChangeEvent, useRef } from "react";
import { useForm } from "react-hook-form";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { PromptContext } from "../../context/PromptContext";
import { ChatContext } from "../../context/ChatContext";
import { LoadingContext } from "../../context/LoadingContext";
import { AppContext } from "../../context/AppContext";
import icon_loading from "../../assets/elysia-loader.gif";
import icon_loading_stopped from "../../assets/icon-loading-stopped.svg";
import { ReactComponent as DocumentIcon } from "../../assets/icon-google-document.svg";
import { ReactComponent as SubmitPromptIcon } from "../../assets/icon-send.svg";
import { ReactComponent as StopPromptIcon } from "../../assets/icon-google-stop.svg";
import { ReactComponent as PrivateChatIcon } from "../../assets/icon-private-chat.svg";
import { ReactComponent as HourglassIcon } from "../../assets/icon-hourglass.svg";
import { ReactComponent as PathIcon } from "../../assets/icon-path.svg";
import { ReactComponent as TickIcon } from "../../assets/icon-google-tick.svg";
import { ReactComponent as PaperclipIcon } from "../../assets/icon-paperclip.svg";
import ChatNavItems from "../home/ChatNavItems";
import { HISTORY_ZERO_STATE } from "../../constants/HistoryConstants";
import { generateChatSessionId } from "../../utils/chat";
import { getContentSignedUrl, uploadFileUsingUrl, triggerIngestion } from "../../services/content";
import { ReactComponent as CloseIcon } from "../../assets/icon-close.svg";

import { FILE_UPLOAD_FAIL_ID, FILE_UPLOAD_TYPE_ID, FILE_UPLOAD_ERROR_ID, FILE_UPLOAD_ACCEPTED_ID, FILE_UPLOAD_SIZE_ID, PROMPT_WORD_LENGTH, CHAT_DISABLED_ID } from "../../config/settings";
import notify from "../../services/notify";
import { ChatSavedPrompt } from "./ChatSavedPrompt";
import { ChatWithDoc } from "../home/ChatWithDoc";
import { ChatWidgets } from "./ChatWidgets";
import OverlayTooltip from "../common/UI/OverlayTooltip";
import { toast } from "react-toastify";

const ChatInput = () => {
  const { register } = useForm();
  const { setPrompt, setProcessed } = useContext(PromptContext);
  const { uploadedFile, setUploadedFile, uploadedFileTitle, setUploadedFileTitle, sources, promptInput, chatAgent, clearState, newChat, setIsChatActive, setPromptInput, setChatAgent, setEntryPoint, setClearState, setActiveChatHistoryRecord, setSessionPath, setNewChat, setChatSessionId, setNextBestActions, setSelectedSources, setIsRestrictedChatSessionStatus, isStreamingRequested, setIsStreamingRequested, isPrivateChat, setIsPrivateChat, setShowScrollIcon, setSources, wordsCount = 0, setWordsCount, wordCountError = false, setWordCountError, localStopStreamingKey = "stopStreaming", showFileToasts, setShowFileToasts, isChatDisabled, availablePrivateDocs, setAvailablePrivateDocs } = useContext(ChatContext);
  const { isTouchDevice } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (window.location.pathname === "/" && queryParams.get("private-chat") === "true") {
    setIsPrivateChat?.(true);
  }
  const { isLoading } = useContext(LoadingContext);
  const { activeChatHistoryRecord } = useContext(ChatContext);
  const [placeholderText, setPlaceHolderText] = useState("");
  const [isPromptError, setIsPromptError] = useState<boolean>(false);
  const [showDocPopup, setShowDocPopup] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [initiatedSavedPrompts, setInitiatedSavedPrompts] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const textareaRef = useRef(document.createElement('textarea'));
  const textareaParent = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const maxPromptHeight = 13 * 22; // show 12 lines of prompt before scrolling. 13 used to account for padding at the top. 22 is line height;
  const inputTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [fileUploadStatus, setFileUploadStatus] = useState<string>("");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Set the initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    switch (chatAgent) {
      case "Document": {
        setPlaceHolderText(ChatNavItems[0].placeHolderText);
        break;
      }
      default: {
        setPlaceHolderText("Ask Elysia anything");
        break;
      }
    }
    // eslint-disable-next-line  
  }, [chatAgent, isPrivateChat]);

  useEffect(() => {
    textareaRef.current.style.height = 'auto'

    if (textareaRef.current.scrollHeight > maxPromptHeight) {
      textareaRef.current.style.height = `${maxPromptHeight}px`;
      textareaRef.current.style.overflowY = 'auto'
    } else {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaRef.current.style.overflowY = 'hidden'
    }
    //eslint-disable-next-line
  }, [promptInput.length]);

  useEffect(() => {
    if (!isTouchDevice) {
      textareaRef.current.focus();
      if (textareaParent.current) {
        textareaParent.current.focus();
      }
    }
    if (isChatDisabled) {
      textareaRef.current.blur();
      notify({
        message: "This is a read only chat because one or more documents from this chat has been deleted",
        type: 'error',
        autoClose: false,
        toastId: CHAT_DISABLED_ID
      });
    } else {
      toast.dismiss(CHAT_DISABLED_ID);
    }
    // eslint-disable-next-line
  }, [clearState, newChat, isChatDisabled]);

  useEffect(() => {
    setPromptInput("");
    // eslint-disable-next-line
  }, [activeChatHistoryRecord?.session_id]);

  useEffect(() => {
    if (showFileToasts === false) {
      toast.dismiss(FILE_UPLOAD_ERROR_ID);
      toast.dismiss(FILE_UPLOAD_ACCEPTED_ID);
      toast.dismiss(FILE_UPLOAD_TYPE_ID);
      toast.dismiss(FILE_UPLOAD_FAIL_ID);
      toast.dismiss(FILE_UPLOAD_SIZE_ID);
    }
  }, [showFileToasts]);

  const uploadFile = async (file: File, title: string) => {
    setLoadingFile(true);
    setFileUploadStatus("Analysing file...");
    try {
      const response = await getContentSignedUrl("private", "", file.name, title || file.name);
      if (response?.url && file) {
        await uploadFileUsingUrl(response.url, file);
        const ingestionRes = await triggerIngestion(
          response.path,
          title || file.name
        );

        setLoadingFile(false);
        if (ingestionRes?.status === "success") {  // status should be sent as pending or in progress form BE
          setChatAgent?.("Document");
          setFileUploadStatus("successful");
          setAvailablePrivateDocs?.([...(availablePrivateDocs || []), file.name.toString()]);
          setTimeout(() => {
            setFileUploadStatus("Added to chat");
          }, 2000);
          notify({
            message: ingestionRes?.message,
            type: 'error',
          });
        } else if (ingestionRes?.status === "accepted") {
          notify({
            message: ingestionRes?.message,
            type: 'error',
            autoClose: false,
            toastId: FILE_UPLOAD_ACCEPTED_ID
          });
          setSources?.([]);
          setSelectedSources?.([]);
          setUploadedFile?.(null);
          setUploadedFileTitle?.("");

        } else if (ingestionRes?.status === "error") {
          notify({
            message: ingestionRes?.message ?? "Something went wrong",
            type: 'error',
            autoClose: false,
            toastId: FILE_UPLOAD_ERROR_ID
          });
          setSources?.([]);
          setSelectedSources?.([]);
          setUploadedFile?.(null);
          setUploadedFileTitle?.("");
          setChatAgent?.("Generic");
        }
      }

    } catch (e: any) {
      setFileUploadStatus("failed");
      const displayMessage = e?.response?.data?.message ?? 'Failed to upload file. Please try again.';
      console.error(e);
      notify({
        message: displayMessage,
        type: 'error',
        autoClose: false,
        toastId: FILE_UPLOAD_FAIL_ID
      });
      setSources?.([]);
      setSelectedSources?.([]);
      setUploadedFile?.(null);
      setUploadedFileTitle?.("");
      setChatAgent?.("Generic");
    } finally {
      setLoadingFile(false);
    }
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    setShowFileToasts?.(true);
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const isValidFileType = [
        'text/plain',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'text/csv',
        'application/csv'
      ].includes(file.type);
      toast.dismiss(FILE_UPLOAD_ERROR_ID);
      toast.dismiss(FILE_UPLOAD_ACCEPTED_ID);
      toast.dismiss(FILE_UPLOAD_TYPE_ID);
      toast.dismiss(FILE_UPLOAD_FAIL_ID);
      toast.dismiss(FILE_UPLOAD_SIZE_ID);

      const isValidSize = file.size <= 31457280; // 30mb
      if (!isValidSize) {
        notify({
          message: "File exceeds the max allowed size of 30 MB. Try uploading a smaller file.",
          type: "error",
          autoClose: false,
          toastId: FILE_UPLOAD_SIZE_ID,
        });
        return;
      }

      if (!isValidFileType) {
        notify({
          message: "Elysia does not support this file type",
          type: "error",
          autoClose: false,
          toastId: FILE_UPLOAD_TYPE_ID,
        });
        return;
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      setUploadedFile?.(file);
      setUploadedFileTitle?.(file.name);

      await uploadFile(file, file.name);
    }
  };


  const handleAttachFileClick = () => {
    fileInputRef.current?.click();
  }

  const startNewTopicChat = () => {
    setSources?.([]);
    setSelectedSources?.([]);
    setShowScrollIcon?.(false);
    setClearState?.(true);
    setNewChat?.(true);
    setChatSessionId?.(generateChatSessionId());
    setChatAgent?.("Generic");
    setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
    navigate("/");
    setPromptInput("");
    setIsChatActive?.(false);
    setSessionPath?.("");
    setUploadedFileTitle?.("");
    setUploadedFile?.(null);
    setShowFileToasts?.(false);
  };

  const handleRemoveFile = () => {
    setUploadedFile?.(null);
    setUploadedFileTitle?.("");
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    startNewTopicChat();
  };

  const stopStreaming = () => {
    sessionStorage.setItem(localStopStreamingKey, "true");
    setIsStreamingRequested?.(false);
  }


  const submitPrompt = () => {
    if (promptInput) {
      if (wordsCount <= PROMPT_WORD_LENGTH) {
        if (chatAgent === 'Document' && sources?.length === 0) {
          notify({
            message: 'Please select a document to proceed.',
            type: 'error'
          });

        } else {
          setIsPromptError(false);
          setClearState?.(false);
          setEntryPoint?.("prompt");
          setPrompt?.(promptInput.trim());
          setProcessed?.(false);
          setPromptInput('');
          setWordsCount?.(0);
        }
      } else {
        setIsPromptError(true);
      }
    } else {
      console.error("Please enter your question in the box provided.")
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !loadingFile) {
      // for a touch enabled device, enter will add a new line and not submit the prompt
      //TODO: Need refining for external keyboard and hybrid devices
      if (isTouchDevice) {
        return;
      }
      if (e.shiftKey) {
        return;
      }
      e.preventDefault();
      if (!isLoading) {
        submitPrompt();
      }
    }
  };

  const createNewSession = () => {
    setClearState?.(true);
    setNewChat?.(true);
    setChatSessionId?.(generateChatSessionId());
    setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
    navigate({
      pathname: "/",
      search: queryParams.toString()
    });
    setIsChatActive?.(false);
    setPromptInput("");
    setSessionPath?.("");
    setIsRestrictedChatSessionStatus?.("");
    setWordsCount?.(0);
    setWordCountError?.(false);
  }

  const handleAddSources = () => {
    setShowDocPopup(true);
    if (chatAgent !== "Document") {
      setSelectedSources?.([]);
      setChatAgent?.("Document");
      setNextBestActions?.(ChatNavItems[0].nextBestActions);
      createNewSession();
    }
  }

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const enteredInput = event.target.value;
    setPromptInput(enteredInput);

    if (inputTimeoutRef.current) {
      clearTimeout(inputTimeoutRef.current);
    }
    const trimmedInput = enteredInput.trim();
    if (!trimmedInput) {
      setWordsCount?.(0);
      setWordCountError?.(false);
      return;
    }
    inputTimeoutRef.current = setTimeout(() => {
      const words = trimmedInput.split(/[\s,]+/).filter(word => word.length > 0);
      setWordsCount?.(words.length);
      if (words.length > PROMPT_WORD_LENGTH) {
        setIsPromptError(true);
        setWordCountError?.(true);
      } else {
        setWordCountError?.(false);
        setIsPromptError(false);
      }
    }, 300)

  }

  const togglePrivateChat = () => {
    createNewSession();
    setChatAgent?.("Generic"); // set default agent when toggled

    if (isPrivateChat) {
      queryParams.delete("private-chat");
    } else {
      queryParams.set("private-chat", "true");
    }
    navigate({
      pathname: "/",
      search: queryParams.toString()
    });

    setIsPrivateChat?.(!isPrivateChat);
    setShowScrollIcon?.(false);
  }

  return (
    <>
      <div className="chat-input">
        <div aria-live="polite" className="sr-only">
          {isLoading ? "Fetching results, please wait" : ""}
        </div>
        {isLoading && (
          <div className="chat-input-loading"><img src={icon_loading} alt="loading" /></div>
        )}
        {!isLoading && (
          <div className="chat-input-loading"><img src={icon_loading_stopped} alt="not_loading" /></div>
        )}

        <div className="w-100">
          <InputGroup className={`chat-input-group ${isPrivateChat ? 'private-chat' : ''} ${isMobile ? 'is-mobile' : ''} `}>
            <ChatWidgets OnTogglePrivateChat={togglePrivateChat} onAddSources={handleAddSources} onInitiatedSavedPrompts={setInitiatedSavedPrompts} onAttachFileClick={handleAttachFileClick} />
            <div className={`text-area-container ${isChatDisabled ? 'disable-prompt' : ''} `} ref={textareaParent}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelect}
                accept=".txt,.pdf,.ppt,.pptx,.doc,.docx,.csv"
              />
              {uploadedFile && (
                <div className="uploaded-file-container" data-testid="uploaded-file-container">
                  <div className=" uploaded-file-preview">
                    <div data-testid="sync-file-icon">
                      <PathIcon />
                    </div>
                    <div className="file-info">
                      <div className="file-info-text">
                        <p data-testid="sync-file-title">{uploadedFileTitle}</p>
                        <p data-testid="sync-file-status">{fileUploadStatus}</p>
                      </div>
                      <div className="uploadedfile-icon-container">
                        {loadingFile ? (<HourglassIcon data-testid="sync-hourglass-icon" />) : (fileUploadStatus === 'successful' ? (<TickIcon className="tickIcon" />) : (<Button
                          variant="link"
                          className="remove-file-btn"
                          onClick={handleRemoveFile}
                        >
                          <CloseIcon className="closeIcon" data-testid="sync-close-button" />
                        </Button>)
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Form.Control {...register('chat-input')}
                className={`bg-white w-100 ${chatAgent === "Document" ? "chat-with-doc" : ""} promt-input`}
                aria-label={placeholderText}
                placeholder={placeholderText}
                name="chat-input"
                as="textarea"
                data-testid="chat-prompt-input"
                rows={1}
                value={promptInput}
                ref={textareaRef}
                onKeyDown={handleKeyDown}
                onInput={handleInput}
                id="prompt-input"
                autoComplete="off"
                disabled={isChatDisabled}
              />
              <div className="d-flex justify-content-between align-items-center w-100 promt-toolbar-container">
                <div>
                  <OverlayTooltip
                    tooltipText="Private Chat"
                    buttonContent={<PrivateChatIcon />}
                    ariaLabel="Private Chat"
                    testId="private-chat"
                    tooltipClassName="tooltip-sidebar"
                    buttonClassName={`private-chat ${isPrivateChat ? "selected" : ""}`}
                    onClick={togglePrivateChat}
                    placement="top-start"
                    disabled={isChatDisabled}
                  />
                  <OverlayTooltip
                    tooltipText="Attach file"
                    buttonContent={<PaperclipIcon />}
                    ariaLabel="Attach File"
                    testId="attach-file"
                    tooltipClassName="tooltip-sidebar"
                    buttonClassName={`attach-file`}
                    onClick={handleAttachFileClick}
                    placement="top-start"
                    disabled={uploadedFileTitle?.length > 0 || chatAgent === "Document" || isChatDisabled}
                  />

                  <OverlayTooltip
                    tooltipText="Add Sources"
                    buttonContent={<DocumentIcon />}
                    ariaLabel="Add Sources"
                    testId="add-sources"
                    tooltipClassName="tooltip-sidebar"
                    buttonClassName={`add-sources ${chatAgent === "Document" ? "selected" : ""}`}
                    onClick={handleAddSources}
                    placement="top-start"
                    disabled={uploadedFileTitle?.length > 0 || isChatDisabled}
                  />
                  {(showDocPopup || loadingFile) && <ChatWithDoc setShowDocPopup={setShowDocPopup} showDocPopup={showDocPopup} />}
                  <ChatSavedPrompt initiated={initiatedSavedPrompts} setInitiated={setInitiatedSavedPrompts} />
                </div>
                <div>
                  {isStreamingRequested ? (
                    <Button
                      aria-label="Stop Streaming"
                      className="stop-button prompt-input-button active"
                      data-testid="stop-streaming"
                      onClick={stopStreaming}
                    >
                      <StopPromptIcon />
                    </Button>
                  ) : (
                    <>
                      <span className={`word-count mx-4 ${wordCountError ? "word-count-error" : ""}`} data-testid="word-count">{wordsCount}/{PROMPT_WORD_LENGTH}</span>
                      <Button
                        aria-label="Submit Prompt"
                        className={`submit-prompt prompt-input-button ${promptInput.trim().length > 0 ? "active" : ""}`}
                        data-testid="submit-prompt"
                        onClick={submitPrompt}
                        disabled={isLoading || isPromptError || !promptInput.trim().length || loadingFile || isChatDisabled}
                      >
                        <SubmitPromptIcon />
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className="chat-mode-container d-block d-md-none">
                {isPrivateChat && <Button aria-label="Private Chat" data-testid="private-chat" onClick={togglePrivateChat} disabled={isChatDisabled}>
                  <PrivateChatIcon />
                </Button>}
                {chatAgent === "Document" && <Button aria-label="Add sources" data-testid="add-sources" onClick={() => handleAddSources()} disabled={uploadedFileTitle?.length > 0 || isChatDisabled}>
                  <DocumentIcon />
                </Button>}
              </div>
            </div>
          </InputGroup>
          <div data-tesid="disclaimer-message" className="chat-input-protection-msg text-start text-md-center">Elysia responses may be inaccurate. Know more about how your data is processed <Link to="https://portal.informa.com/sites/essentials/my-hr/document/162590/Global-Workplace-Privacy-Statement" target="_blank">here</Link></div>
        </div>
      </div>
    </>
  );
};

export default ChatInput;
