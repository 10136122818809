// components
import { useEffect, useContext, useRef } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from "../components/common/Menu";
import { ChatContext } from "../context/ChatContext";
import { AppContext } from "../context/AppContext";
import Sidebar from "../components/common/Sidebar";
import icon_loading from "../assets/elysia-loader.gif"

const Layout = ({ children }) => {

  const { isActiveChatHistoryLoading } = useContext(ChatContext);
  const { isSideNavOpen, setIsSideNavOpen, isTouchDevice, setIsTouchDevice } = useContext(AppContext);

  const panelContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    localStorage.setItem('sidebarOpen', isSideNavOpen.toString());
  }, [isSideNavOpen]);


  useEffect(() => {
    const checkTouchDevice = () => {
      const isTouch = 'ontouchstart' in window ||
        navigator.maxTouchPoints > 0;
      setIsTouchDevice(isTouch);
    };

    checkTouchDevice();
    window.addEventListener('resize', checkTouchDevice);
    return () => {
        window.removeEventListener('resize', checkTouchDevice);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const setHeight = () => {
      if (panelContainerRef.current) {
        const height = window.innerHeight;
        panelContainerRef.current.style.height = `${height}px`;
      }
    };

    setHeight();

    window.addEventListener('resize', setHeight);
    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  const handleSidebarClose = () => {
    if (window.innerWidth < 1260) {
      setIsSideNavOpen(false);
    }
  }

  return (
    <div className={`${isTouchDevice ? "touch-device" : ""}`}>
      <div ref={panelContainerRef} className={`panel-container d-flex ${isSideNavOpen ? "sidebar-expand" : "sidebar-collapse"}`}>
        <div className="left-panel panel d-flex flex-column">
          <Sidebar></Sidebar>
        </div>
        <div className="panel right-panel px-0 d-flex flex-column flex-grow-1" onClick={handleSidebarClose}>
          <div className="content-fixed-top"><Menu /></div>
          {isActiveChatHistoryLoading ? (
            <div className="active-history-loading"><img src={icon_loading} alt="loading" /></div>
          ) : (
            <>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              {children}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
