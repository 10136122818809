import { catchError } from "./common";
import axios from "axios";

export const getDashboardToken = async (): Promise<any> => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}v1/ai/dashboard/powerbi`);
        return response.data;
    } catch (e: any) {
        console.error(e);
        catchError(e);
    }
}
